<template>
  <modal
    classes="w-75"
    :title="
      $t(
        'documents_page.complete_your_profile_modal.Complete_your_company_information'
      )
    "
    @close="$emit('close')"
  >
    <template slot="body">
      <form-company-information @save="$emit('close')" />
    </template>
    <template slot="footer">
      <button class="btn btn-outline-default" @click="$emit('close')">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "../Modal";
import FormCompanyInformation from "@/components/SettingsPage/FormCompanyInformation";

export default {
  components: {
    FormCompanyInformation,
    Modal,
  },
};
</script>
