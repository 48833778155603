<template>
  <div class="row w-100 mt-4 hide_in_print">
    <div class="col-md-4">
      <router-link :to="{ name: 'documents' }" class="ml-4">
        <span class="ti-arrow-left text-dark" />
        <h4 class="d-inline text-gray">
          {{ $t("commons.buttons.Back_to_dashboard") }}
        </h4>
      </router-link>
      <div class="d-inline-block"><slot></slot></div>
    </div>
    <div class="col-md-8 text-right">
      <div class="row justify-content-end">
        <span :class="{ 'ti-reload': is_saving }" class="col-md-auto">
          {{ saveText }}
        </span>
        <div class="col-md-auto pointer" v-if="is_taskList">
          <a @click="showTaskListSidebar">
            <span class="ti-check icon"></span>
            {{ $t("navbar_editor.task_list") }}
          </a>
        </div>
        <div class="col-md-auto pointer">
          <a @click="showDefaultSpreadsheet">
            <span class="ti-layout-grid4-alt icon"></span>
            {{ $t("navbar_editor.spreadsheet") }}
          </a>
        </div>
        <scratchpad
          v-if="authorId"
          class="col-md-auto"
          :authorId="authorId"
          :authorName="authorName"
        />
        <template v-if="commentThreadList.length">
          <drop-down
            class="col-md-auto"
            id="commentsdropdown"
            :title="$t('navbar_editor.Comments_history')"
            icon="ti-comments"
          >
            <a
              v-for="(item, index) in commentThreadList"
              :key="index"
              class="dropdown-item max-w-25em"
              @click="loadCommentThread(item.threadID)"
            >
              <div id="commentblock">
                <div class="row">
                  <b class="col-5 pl-0 author text-elipsis"
                    >{{ item.author }} :</b
                  >
                  <span class="col-7 pl-0 date text-elipsis">{{
                    item.date | formatDate
                  }}</span>
                </div>
                <div class="row">
                  <div class="col-9 comment ml-2">
                    <span style="white-space: normal">
                      "{{ item.firstComment }}"
                    </span>
                  </div>
                  <div class="col-2 notify">
                    <span class="numcomments mr-1">{{
                      item.numberOfComments
                    }}</span>
                    <span class="ti-comment icon"></span>
                  </div>
                </div>
              </div>
            </a>
          </drop-down>
        </template>
        <template v-if="documentVersionsList.length">
          <drop-down
            class="col-md-auto"
            id="vcsdropdown"
            :title="$t('navbar_editor.Version_history')"
            icon="ti-time"
          >
            <a
              v-for="item in documentVersionsList"
              :key="item.vid"
              class="dropdown-item"
              @click="selectVersion(item.vid)"
              >{{ item.date | formatDate }}
              <template v-if="item.name"> , {{ item.name }} </template>
            </a>
          </drop-down>
        </template>
        <drop-down
          class="col-md-auto"
          id="exportpdfdropdown"
          :title="$t('navbar_editor.export_pdf.Export_pdf')"
          icon="ti-files"
        >
          <a
            v-for="(format, name) in pdfFormats"
            v-bind:key="name"
            class="dropdown-item"
            @click="exportPDF(format)"
            >{{ $t("navbar_editor.export_pdf.Export") }} {{ name }}</a
          >
        </drop-down>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>
<script>
import EventBus from "../../eventBus";
import Scratchpad from "@/components/scratchpad";

export default {
  props: {
    is_saving: {
      type: Boolean,
      default: false,
    },
    authorId: String,
    authorName: String,
    is_taskList: Boolean,
  },
  components: {
    Scratchpad,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      documentVersionsList: [],
      commentThreadList: [],
      saveText: "",
      pdfFormats: {
        [this.$t("navbar_editor.export_pdf.A4_Portrait")]: {
          format: "A4",
          landscape: false,
        },
        [this.$t("navbar_editor.export_pdf.A4_Landscape")]: {
          format: "A4",
          landscape: true,
        },
        [this.$t("navbar_editor.export_pdf.A3_Portrait")]: {
          format: "A3",
          landscape: false,
        },
        [this.$t("navbar_editor.export_pdf.A3_Landscape")]: {
          format: "A3",
          landscape: true,
        },
      },
    };
  },
  watch: {
    is_saving: function (isSavingNow, wasSaving) {
      if (!wasSaving && isSavingNow) {
        this.saveText = this.$t("notifications.Saving");
      } else {
        this.saveText = this.$t("notifications.Saved");
        setTimeout(() => {
          this.saveText = "";
        }, 2000);
      }
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    selectVersion(vid) {
      EventBus.$emit("loadDocumentVersion", vid);
    },
    exportPDF(format) {
      EventBus.$emit("exportPdf", format);
    },
    loadCommentThread(threadID) {
      EventBus.$emit("loadCommentsThreadHistory", threadID, true);
    },
    showTaskListSidebar() {
      EventBus.$emit("showTaskListSidebar");
    },
    showDefaultSpreadsheet() {
      EventBus.$emit("showDefaultSpreadsheet");
    },
  },
  created() {
    EventBus.$once("versionDatesInfo", (data) => {
      this.documentVersionsList = data;
    });
    EventBus.$once("commentsHistoryData", (data) => {
      this.commentThreadList = data;
    });
  },
};
</script>
<style lang="scss">
#commentblock {
  min-width: 20rem;
  .date {
    font-size: smaller;
  }
}

.ti-arrow-left {
  transition: all 0.1s ease-in-out;
}
.ti-arrow-left:hover {
  transform: scale(1.5);
}
.dropdown-item {
  /* position: relative; */
}
</style>
