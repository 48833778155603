<template>
  <div class="content min-vh-100 mb-5">
    <transition name="fade" mode="out-in">
      <div class="container-fluid">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
export default {};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
  .fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
