export default {
  getUser: function () {
    return JSON.parse(localStorage.getItem("user"));
  },

  updateUser: function (obj) {
    let user = JSON.parse(localStorage.getItem("user"));
    Object.assign(user, obj);
    localStorage.setItem("user", JSON.stringify(user));
    return user;
  },

  removeUser: function () {
    localStorage.removeItem("token");
  },

  isProfileComplete: function () {
    const user = Object(this.getUser());
    return (
      user &&
      user.name &&
      user.company &&
      user.company.sector &&
      user.company.name &&
      user.company.address &&
      user.company.address.firstLine &&
      user.company.address.city &&
      user.company.address.country &&
      user.company.address.postalCode
    );
  },
};
