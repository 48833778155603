import { backendClient } from "@/common/api/backend.client";

export const userApi = {
  getUser() {
    return backendClient.get("/getUser");
  },

  updateCoaches(coachesList) {
    return backendClient.put("/updateCoaches", {
      coaches: coachesList,
    });
  },

  updateCoachesForAnotherUser(userId, coachesList) {
    return backendClient.put("/updateCoaches/" + userId, {
      coaches: coachesList,
    });
  },

  addCollaborator(collaborator) {
    return backendClient.put("/addCollaborators", {
      collaborators: collaborator,
    });
  },

  removeCollaborator(collaborator) {
    return backendClient.delete("/removeCollaborators", {
      data: {
        collaborators: collaborator,
      },
    });
  },

  updateSettings(settings) {
    return backendClient.put("/updateSettings", { settings: settings });
  },

  updateCompanyInformation(companyPayload) {
    return backendClient.put("/updateCompany", companyPayload);
  },

  updateProfile(profilePayload) {
    return backendClient.put("/updateProfile", profilePayload);
  },

  updateUserRole(userId, role) {
    return backendClient.put("/updateRole/" + userId, { role: role });
  },

  getAvailableCoachesList() {
    return backendClient.get("/getCoaches");
  },

  getAllUsers() {
    return backendClient.get("/getAllUsers");
  },

  createInvitation(payload) {
    return backendClient.post("/invitation", payload);
  },

  getScratchpad(userId) {
    return backendClient.get("/scratchpad/" + userId);
  },

  updateScratchpad(userId, content) {
    return backendClient.put("/scratchpad/" + userId, {
      content: content,
    });
  },
};
