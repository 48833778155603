import FormGroupInput from "./Inputs/formGroupInput.vue";

import DropDown from "./Dropdown.vue";
import PaperTable from "./PaperTable.vue";
import Button from "./Button";

import Card from "./Cards/Card.vue";
import StatsCard from "./Cards/StatsCard.vue";

import SidebarPlugin from "./SidebarPlugin/index";

import Editor from "./Editor_Page/Editor.vue";
import InlineEdit from "./InlineEdit.vue";

let components = {
  FormGroupInput,
  Card,
  StatsCard,
  PaperTable,
  DropDown,
  SidebarPlugin,
  Editor,
};

export default components;

export {
  FormGroupInput,
  Card,
  StatsCard,
  PaperTable,
  DropDown,
  Button,
  SidebarPlugin,
  Editor,
  InlineEdit,
};
