<template>
  <modal
    classes="modal-info w-75 text-black"
    @close="$emit('close')"
    :title="title"
  >
    <template slot="body">
      <quill-editor :value="content" ref="editor" :options="quillOptions" />
    </template>
    <template slot="footer">
      <button class="btn btn-outline-default" @click="$emit('close')">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "../Modal";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    Modal,
    quillEditor,
  },
  props: {
    title: String,
    content: String,
  },
  data() {
    return {
      quillOptions: { modules: { toolbar: false } },
    };
  },
  mounted() {
    this.$refs.editor.quill.disable();
  },
};
</script>
<style lang="scss">
.modal-info .ql-editor {
  min-height: 10rem;
}
</style>
