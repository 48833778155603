<template>
  <div class="commentbar">
    <div class="row">
      <span class="ti-comments commenticon" />
      <button
        v-if="commentsToDisplay && !resolved"
        @click="resolveCommentThread"
        class="btn btn-default btn-sm top-right"
      >
        {{ $t("commons.buttons.Resolve") }}
      </button>
      <a
        v-if="commentsToDisplay && resolved"
        @click="$emit('go-to-version')"
        class="btn btn-link top-right"
      >
        <u>{{ $t("comment_bar.Original_version") }}</u>
      </a>
    </div>
    <div v-if="commentsToDisplay">
      <div
        v-for="(comment, idx) in commentsToDisplay.comments"
        :key="idx"
        class="commentblock"
      >
        <div class="row">
          <b class="col-4 author">{{ comment.author.name }}</b>
          <span class="col-8 date">
            {{ comment.date | formatDate }}
          </span>
        </div>
        <div class="row description m-3" style="white-space: pre-line">
          {{ comment.text }}
        </div>
      </div>
    </div>
    <template>
      <br />
      <textarea
        v-if="!resolved"
        type="text"
        id="commentid"
        ref="commentinput"
        class="textarea form-control border-input hide_in_print"
        :placeholder="$t('comment_bar.Write_your_comment_here')"
      />
      <div class="row my-4">
        <div class="col-6" style="margin-bottom: 5%">
          <button
            v-on:click="toggleSidebar"
            type="button"
            class="btn btn-outline-default hide_in_print w-100"
          >
            {{ $t("commons.buttons.Close") }}
          </button>
        </div>
        <div class="col-6">
          <button
            v-if="!resolved"
            v-on:click="onCommentSubmit"
            type="button"
            id="comment-submit-button"
            class="btn btn-yellow hide_in_print w-100"
          >
            {{ $t("commons.buttons.Submit") }}
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import EventBus from "../eventBus";

export default {
  name: "CommentBar",

  data() {
    return {};
  },
  props: {
    commentsToDisplay: { type: Object },
    resolved: { type: Boolean },
  },

  methods: {
    onCommentSubmit() {
      let commentInput = this.$refs["commentinput"].value;

      if (commentInput == "" || commentInput == null) {
        this.$toast.warning(`${this.$t("notifications.Write_the_comment")}`);
        return null;
      }
      EventBus.$emit("commentTextSubmitted", commentInput);
      this.$refs["commentinput"].value = "";
    },
    resolveCommentThread() {
      EventBus.$emit("resolveCommentsThread");
      EventBus.$emit("hideSidebar");
    },
    toggleSidebar() {
      EventBus.$emit("toggleCommentSidebar", {});
    },
  },
};
</script>

<style>
.textarea {
  min-height: 20em;
}

.date {
  font-size: smaller;
  text-align: end;
}
.commenticon {
  margin-top: 0.5rem;
  margin-left: 1rem;
}
.top-right {
  display: block;
  margin-left: auto;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}
/* #commentid{
  width: 100%;
} */
</style>
