<template>
  <div id="summaryTable" class="table-full-width table-responsive">
    <table class="table table-hover">
      <thead>
        <th>Id</th>
        <th v-for="(item, i) in timelineWithSummedWeights" :key="i">
          {{ item.date }}
        </th>
      </thead>

      <tbody>
        <tr v-for="(chart, i) in chartsData" :key="i">
          <td>{{ chartsData[i].name }}</td>
          <td v-for="(monthTotal, j) in summaryPerGoal[i]" :key="j">
            {{ monthTotal.weight }}
          </td>
        </tr>
        <tr>
          <td>
            <h6>{{ $t("gantt_page.weights_summary.Total") }}:</h6>
          </td>
          <td v-for="(item, i) in timelineWithSummedWeights" :key="i">
            <h6>{{ item.weight }}</h6>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    chartsData: Array,
  },

  data() {
    return {
      timelineWithSummedWeights: [],
      summaryPerGoal: [],
    };
  },

  watch: {
    chartsData: {
      handler: function () {
        let summaryTemp = [];

        for (const chartIx in this.chartsData) {
          let currentGanttData = this.chartsData[chartIx].data;
          let endBar;

          const recurrentEventsPresent = currentGanttData.some((event) => {
            return event.frequency.key !== "once";
          });

          if (recurrentEventsPresent) {
            endBar = moment(this.chartsData[chartIx].endPeriod).diff(
              moment(this.chartsData[chartIx].startPeriod),
              "months",
              true
            );
          } else {
            endBar = Math.max.apply(
              Math,
              currentGanttData.map(function (m) {
                return m.offset + m.duration;
              })
            );
          }

          let sumChart = [];

          for (let step = 1; step < endBar; step++) {
            let sumTask = [];

            for (const i in currentGanttData) {
              //count tasks without repetition
              const event = currentGanttData[i];
              if (!event.weight) {
                sumTask.push(0);
                continue;
              }
              if (
                event.offset <= step &&
                event.offset + event.duration - 1 >= step
              )
                sumTask.push(parseFloat(event.weight));
              //count tasks with repetition
              else if (
                event.frequency.key != "once" &&
                step >= event.duration + event.offset
              ) {
                if (
                  event.frequency.key === "monthly" &&
                  (step - event.offset) % 1 < event.duration
                ) {
                  sumTask.push(parseFloat(event.weight));
                }
                if (
                  event.frequency.key === "quarterly" &&
                  (step - event.offset) % 3 < event.duration
                ) {
                  sumTask.push(parseFloat(event.weight));
                }

                if (
                  event.frequency.key === "yearly" &&
                  (step - event.offset) % 12 < event.duration
                ) {
                  sumTask.push(parseFloat(event.weight));
                }
              } else {
                sumTask.push(0);
              }
            }

            let total = sumTask.reduce((a, b) => a + b, 0);

            total = parseFloat(total.toFixed(3));

            sumChart.push({
              date: moment(this.chartsData[chartIx].startPeriod)
                .add(step, "months")
                .format("MMM-YYYY"),
              weight: total,
            });
          }

          summaryTemp[chartIx] = sumChart;
        }

        let fullArray = [].concat.apply([], summaryTemp);
        let holder = {};

        fullArray.forEach(function (d) {
          if (holder[d.date]) {
            holder[d.date] = parseFloat((holder[d.date] + d.weight).toFixed(3));
          } else {
            holder[d.date] = d.weight;
          }
        });

        let dateAndTotal = [];
        for (var prop in holder) {
          dateAndTotal.push({ date: prop, weight: holder[prop] });
        }

        this.summaryPerGoal = summaryTemp;
        this.timelineWithSummedWeights = dateAndTotal;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style></style>
