<template>
  <div>
    <a class="pointer" @click="showScratchpad = true">
      <i class="ti-notepad" />
      <span> {{ $t("commons.buttons.Scratchpad") }} </span>
    </a>
    <modal-scratchpad-edit
      :scratchpadContent="scratchpadContent"
      :author-name="authorName"
      v-if="showScratchpad"
      @update="updateScratchpadContent"
      @close="showScratchpad = false"
    />
  </div>
</template>

<script>
import ModalScratchpadEdit from "@/components/ModalScratchpadEdit";
import { userApi } from "@/common/api";
import { errorHandling } from "../services/ErrorService";

export default {
  name: "scratchpad",
  components: {
    ModalScratchpadEdit,
  },
  props: {
    authorName: String,
    authorId: String,
  },
  data() {
    return {
      showScratchpad: false,
      scratchpadContent: "",
    };
  },
  methods: {
    async getScratchpad() {
      await userApi
        .getScratchpad(this.authorId)
        .then((res) => {
          this.scratchpadContent = res.data.content;
        })
        .catch((e) => {
          console.error("cannot get the scratchpad", e);
          errorHandling(e);
        });
    },
    async updateScratchpadContent(content) {
      this.scratchpadContent = content;
      await userApi.updateScratchpad(this.authorId, content).catch((e) => {
        console.error("cannot get the scratchpad", e);
        errorHandling(e);
      });
    },
  },
  created() {
    this.getScratchpad();
  },
};
</script>

<style scoped></style>
