<template>
  <div>
    <top-navbar-editor
      :is_saving="d$_isSaving"
      :authorId="d$_author._id"
      :authorName="d$_author.name"
      :is_taskList="true"
    >
      <h4
        class="ml-5 text-black pointer my-0"
        @click="showInformationModal = true"
      >
        Roadmap
        <small class="ml-auto mr-3"
          ><img class="h-1rem mb-1" src="@/assets/img/infoicon.png"
        /></small>
      </h4>
    </top-navbar-editor>
    <dashboard-content>
      <div class="row">
        <h5 class="col-6" v-if="!$_isNewestVersion && $_currentVersionId">
          {{ $t("versions.Version") }}:
          {{ d$_currentVersion.date | formatDate }}.
          <small>{{ $t("versions.Autosave_is_off") }}</small>
          <br />
          <span class="text-info pointer" @click="$_saveAndReload">
            {{ $t("versions.Restore_to_this_version") }}
          </span>
          /
          <span class="text-info pointer" @click="$router.go()">
            {{ $t("versions.Back_to_the_newest_version") }}
          </span>
        </h5>
      </div>
      <div class="row">
        <h3 class="col-12" style="text-align: center">{{ d$_title }}</h3>
      </div>

      <div class="row">
        <div
          :class="[d$_sidebarActive ? 'col-9' : 'col-12']"
          class="roadmap-card"
        >
          <div
            v-for="(chart, index) in chartsData"
            :key="index"
            v-on:click="activeChartIndex = index"
          >
            <transition name="fade">
              <card>
                <inline-edit v-model="chart.name">
                  <h4 id="goaltitle">{{ chart.name }}</h4>
                  <span
                    class="ti-trash icon-button grow text-gray ml-2"
                    @click="toggleRemoveChartModal(index)"
                  />
                  <span
                    class="ti-angle-double-down icon-button grow mx-2 text-gray"
                    @click.stop="moveChartDown(index)"
                  />
                  <span
                    class="ti-angle-double-up icon-button grow text-gray"
                    @click.stop="moveChartUp(index)"
                  />
                </inline-edit>

                <span
                  v-if="chart.commentsThreadID || $_isNewestVersion"
                  id="gantt-comment-icon"
                  class="ti-comment-alt icon-button grow hide_in_print"
                  :class="{ 'text-success': chart.commentsThreadID }"
                  @click="showCommentsThreadForGoal(index)"
                />
                <gantt-card
                  :chartIx="index"
                  :activeChart="activeChartIndex"
                  :goalName="chart.name"
                  :ganttData="chart.data"
                  :initialGrouping="chart.initialGrouping"
                  :colors="chart.colors"
                  :timescaleunit="chart.timescaleunit"
                  :grouptimescaleunits="chart.grouptimescaleunits"
                  :startPeriod="chart.startPeriod"
                  :endPeriod="chart.endPeriod"
                  @edit-initial-grouping="
                    $set(chart, 'initialGrouping', $event)
                  "
                />
              </card>
            </transition>
          </div>
        </div>
        <div class="col-md-3">
          <transition name="fade">
            <card v-show="d$_commentSidebarActive" class="comment-sidebar">
              <CommentBar
                @close="d$_commentSidebarActive = false"
                ref="commentbar"
                :commentsToDisplay="d$_currentCommentsThread"
                :resolved="d$_currentThreadResolved || !$_isNewestVersion"
                @go-to-version="$_findAndLoadVersionForCurrentCommentsThread()"
              ></CommentBar>
            </card>
          </transition>
          <div v-show="d$_showTaskListSidebar">
            <TaskList />
          </div>
        </div>
      </div>
      <div
        v-if="chartsData.length"
        class="row"
        style="z-index: 0; position: relative"
      >
        <div class="col-md-12">
          <card>
            <h4 class="card-title">
              {{ $t("gantt_page.weights_summary.Weights_Summary") }}
            </h4>
            <summary-table
              :chartsData="chartsData"
              class="card-body"
            ></summary-table>
          </card>
        </div>
      </div>

      <div class="row hide_in_print" style="z-index: 0; position: relative">
        <div class="col-md-9">
          <card>
            <div class="form-group">
              <h4>{{ $t("gantt_page.create_gantt.Create_a_new_goal") }}</h4>
              <label class="control-label">{{
                $t("gantt_page.create_gantt.Name_of_the_goal")
              }}</label>
              <div class="row">
                <div class="col-md-3">
                  <input
                    aria-describedby="addon-right addon-left"
                    type="text"
                    :placeholder="$t('gantt_page.create_gantt.Name')"
                    class="form-control border-input"
                    v-model="chartName"
                  />
                </div>
                <div class="col-md-3">
                  <div id="color-picker">
                    <color-picker-group
                      :colorOptions="colors"
                      v-model="selectedColorGroup"
                    ></color-picker-group>
                  </div>
                </div>
                <div class="col">
                  <button
                    id="createChartButton"
                    type="button"
                    class="btn btn-default"
                    @click="addChart"
                  >
                    {{ $t("commons.buttons.Create_goal") }}
                  </button>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </dashboard-content>
    <modal-roadmap-information
      v-if="showInformationModal"
      @close="showInformationModal = false"
    />
    <modal-external-document
      v-if="d$_showDefaultSpreadsheetModal"
      @close="d$_showDefaultSpreadsheetModal = false"
      :url="d$_spreadsheetURL"
      :author-name="d$_author.name"
      :document-id="d$_externalDocumentId"
    />
    <GeneralModal
      v-if="showRemoveChartModal"
      :title="$t('commons.confirms.Confirm_on_delete')"
      :acceptText="$t('commons.buttons.Yes')"
      :cancelText="$t('commons.buttons.No')"
      width="w-25"
      @close="toggleRemoveChartModal"
      @accept="removeChart"
    />
  </div>
</template>

<script>
import GanttCard from "../components/Gantt_Page/GanttCard";
import ColorPickerGroup from "../components/Gantt_Page/ColorPickerGroup";
import CommentBar from "../components/CommentBar.vue";
import { InlineEdit } from "../components/index";
import EventBus from "../eventBus";
import moment from "moment";
import { colorSets } from "../common/colors";
import SummaryTable from "../components/Gantt_Page/SummaryTable.vue";
import documentEditorMixin from "../mixins/documentEditorMixin";
import ModalRoadmapInformation from "@/components/Gantt_Page/ModalRoadmapInformation";
import TopNavbarEditor from "@/layout/dashboard/TopNavbarEditor";
import DashboardContent from "../layout/dashboard/Content.vue";
import TaskList from "../components/Documents_Page/TaskList";
import ModalExternalDocument from "@/components/ModalExternalDocument";
import GeneralModal from "../components/Modals/GeneralModal";

export default {
  mixins: [documentEditorMixin],
  components: {
    ModalRoadmapInformation,
    TopNavbarEditor,
    GanttCard,
    CommentBar,
    ColorPickerGroup,
    InlineEdit,
    SummaryTable,
    DashboardContent,
    TaskList,
    ModalExternalDocument,
    GeneralModal,
  },

  data() {
    return {
      chartName: "",
      chartsData: [],
      selectedColorGroup: [],
      colors: colorSets,
      activeChartIndex: null,
      showInformationModal: false,
      showRemoveChartModal: false,
      chartIndexToRemove: null,
    };
  },

  methods: {
    addChart() {
      if (
        !this.chartName ||
        this.chartName.length < 4 ||
        !this.selectedColorGroup ||
        this.selectedColorGroup.length < 1
      ) {
        this.$toast.warning(
          `${this.$t("notifications.gantt_page.Enter_the_name_of_the_goal")}`
        );
        return;
      }

      let newChart = {
        name: this.chartName,
        colors: this.selectedColorGroup,
        timescaleunit: "months",
        grouptimescaleunits: 3,
        startPeriod: moment()
          .startOf("year")
          .subtract(1, "month")
          .toISOString(),
        endPeriod: moment()
          .startOf("year")
          .subtract(1, "month")
          .add(10, "years")
          .toISOString(),
        commentsThreadID: null,
        data: [
          {
            id: 0,
            title: this.$t("gantt_page.gantt_card.Empty_task"),
            offset: 1,
            duration: 4,
            status: this.selectedColorGroup[0].name.toLowerCase(),
            x: 0,
            width: 0,
            weight: null,
            frequency: {
              key: "once",
            },
            group_by: "",
          },
        ],
        docSummary: [],
      };

      this.chartsData.push(newChart);
      this.chartName = "";
    },

    toggleRemoveChartModal(index) {
      this.chartIndexToRemove = index;
      this.showRemoveChartModal = !this.showRemoveChartModal;
    },

    removeChart() {
      this.activeChartIndex = null;
      this.chartsData.splice(this.chartIndexToRemove, 1);
      this.toggleRemoveChartModal();
    },

    moveChartUp(index) {
      this.chartsData.splice(index - 1, 0, this.chartsData.splice(index, 1)[0]);
      this.activeChartIndex = null;
    },

    moveChartDown(index) {
      this.chartsData.splice(index + 1, 0, this.chartsData.splice(index, 1)[0]);
      this.activeChartIndex = null;
    },

    showCommentsThreadForGoal(activeChartIndex) {
      if (!this.d$_commentSidebarActive) {
        this.d$_currentThreadResolved = false;
        this.d$_commentSidebarActive = true;
        this.activeChartIndex = activeChartIndex;
        let activeChart = this.chartsData[activeChartIndex];
        setTimeout(() => {
          this.$refs.commentbar.$refs.commentinput.focus();
        }, 200);
        if (activeChart.commentsThreadID) {
          this.d$_currentCommentsThread = this.$_getCommentsThreadById(
            activeChart.commentsThreadID
          );
        } else {
          this.d$_currentCommentsThread = null;
        }
      } else {
        this.d$_commentSidebarActive = false;
      }
    },

    resolveComment() {
      this.chartsData[this.activeChartIndex].commentsThreadID = null;
      this.d$_commentSidebarActive = false;
    },
  },

  created() {
    EventBus.$on("commentsThreadCreated", (id) => {
      this.chartsData[this.activeChartIndex].commentsThreadID = id;
    });
    EventBus.$on("resolveCommentsThread", this.resolveComment);
    this.d$_currentVersion = { content: this.chartsData };
  },

  watch: {
    $_currentVersionId: function () {
      this.chartsData = this.d$_currentVersion.content;
    },
  },

  beforeDestroy() {
    EventBus.$off("resolveCommentsThread");
    EventBus.$off("commentsThreadCreated");
  },
};
</script>

<style lang="scss" scoped>
#goaltitle {
  margin: 0 0 0;
  display: inline;
}
.ti-close {
  margin-left: 1rem;
}

#createChartButton {
  position: relative;
  bottom: 0;
  right: 1rem;
}

#gantt-comment-icon {
  position: absolute;
  right: 1rem;
}

#summaryTable {
  overflow-x: scroll;
}
.table {
  .td {
    width: 25px;
  }
}

.comment-sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 1rem;
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
}

.roadmap-card {
  margin-bottom: 20px;

  .card {
    height: 100%;
  }
}
</style>
