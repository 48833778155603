<template>
  <div class="wrapper">
    <div class="main-panel full-width">
      <top-navbar></top-navbar>
      <dashboard-content>
        <router-view />
      </dashboard-content>
      <footer-copied></footer-copied>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import DashboardContent from "./Content.vue";
import TopNavbar from "./TopNavbar";
import FooterCopied from "@/layout/dashboard/FooterCopied";

export default {
  components: {
    FooterCopied,
    DashboardContent,
    TopNavbar,
  },
};
</script>
<style lang="scss" scoped>
.main-panel.full-width {
  width: 100%;
}

.logo {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  height: 7rem;
  width: auto;
  z-index: -1;
}
</style>
