<template>
  <div class="card">
    <div class="card-header">
      <div class="d-flex">
        <h4 class="card-title mb-2 mr-3">
          {{ $t("documents_page.tasks_list.Tasks") }}
        </h4>
      </div>
      <transition name="slide">
        <div>
          <select
            v-if="allAssignees.length > 1"
            class="form-control border-input mb-2"
            v-model="currentAssignee"
          >
            <option
              v-for="(assignee, idx) in allAssignees"
              :key="idx"
              :value="assignee"
            >
              {{ assignee.name }}
            </option>
          </select>
        </div>
      </transition>
      <transition name="slide">
        <div class="input-group group-adding" v-if="!readOnly">
          <input
            type="text"
            class="form-control border-input"
            :placeholder="$t('documents_page.tasks_list.new_group_name')"
            v-model="newGroupName"
            v-on:keyup.enter="createNewTasksGroup()"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-success"
              type="button"
              @click="createNewTasksGroup()"
            >
              <span class="ti-plus" />
            </button>
          </div>
        </div>
      </transition>
      <ul class="nav nav-tabs nav-fill">
        <li class="nav-item pointer">
          <a
            class="nav-link"
            :class="{ active: !showOnlyDoneTasks }"
            @click="showOnlyDoneTasks = false"
            >{{ $t("documents_page.tasks_list.open") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link pointer"
            :class="{ active: showOnlyDoneTasks }"
            @click="showOnlyDoneTasks = true"
            >{{ $t("documents_page.tasks_list.done") }}</a
          >
        </li>
      </ul>
    </div>
    <div class="card-content">
      <table class="table">
        <tbody>
          <tr v-for="(group, groupIdx) in tasksGroups" :key="groupIdx">
            <td>
              <inline-edit
                class="d-inline-block w-100"
                v-model="group.name"
                icon-class="float-right"
                @input="currentTasksGroupIdx = groupIdx"
                v-if="!readOnly"
              >
                <span>{{ group.name }}</span>
                <span
                  class="ti-trash icon-button grow text-gray my-auto mx-2 float-right"
                  @click="toggleDeleteGroupModal(groupIdx)"
                />
              </inline-edit>
              <b v-else> {{ group.name }} </b>
              <draggable
                class="mt-2"
                :list="group.tasks"
                ghost-class="ghost"
                group="allTasks"
                @change="updateTasksGroup(groupIdx)"
                :disabled="readOnly"
                @start="
                  dragging = true;
                  currentTasksGroupIdx = groupIdx;
                "
                @end="dragging = false"
              >
                <card
                  class="task-card text-left"
                  :class="{ grab: !readOnly, done: showOnlyDoneTasks }"
                  v-for="(task, taskIdx) in getTasksToShowFromGroup(groupIdx)"
                  :key="taskIdx"
                >
                  <span
                    @click="openModal(task, groupIdx)"
                    class="pointer w-100"
                  >
                    <del v-if="showOnlyDoneTasks">
                      {{ task.taskTitle }} &nbsp;
                    </del>
                    <template v-else> {{ task.taskTitle }} &nbsp; </template>
                  </span>
                </card>
              </draggable>
              <div @click="createNewTask(groupIdx)" class="text-center">
                <card class="task-card add-task-button" v-if="!readOnly">
                  <span class="ti-plus text-gray" />
                  {{ $t("documents_page.tasks_list.Create_a_new_task") }}
                </card>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-6" style="margin-bottom: 3%" v-if="include_closebutton">
        <button
          type="button"
          class="btn btn-outline-default hide_in_print w-100"
          @click="showTaskListSidebar"
        >
          {{ $t("commons.buttons.Close") }}
        </button>
      </div>
      <modal-task-edit
        v-if="modalActive && !readOnly"
        :taskTitle="currentTask.taskTitle"
        :taskContent="currentTask.taskContent"
        :assigneeName="currentAssignee.name"
        :author="currentTask.author.name"
        :date="new Date(currentTask.date)"
        @close="modalActive = false"
        @delete-task="deleteCurrentTask()"
        @update-task-title="$set(currentTask, 'taskTitle', $event)"
        @update-task-content="$set(currentTask, 'taskContent', $event)"
        @mark-task-done="markCurrentTaskAsDone()"
        @save-and-close="
          updateCurrentTasksGroup();
          modalActive = false;
        "
      />
      <modal-task-detail
        v-if="modalActive && readOnly"
        :taskTitle="currentTask.taskTitle"
        :taskContent="currentTask.taskContent"
        :assigneeName="currentAssignee.name"
        :author="currentTask.author.name"
        :date="new Date(currentTask.date)"
        @close="modalActive = false"
      />
    </div>
    <GeneralModal
      v-if="showDeleteGroupModal"
      :title="$t('commons.confirms.Confirm_on_delete')"
      :acceptText="$t('commons.buttons.Yes')"
      :cancelText="$t('commons.buttons.No')"
      width="w-25"
      @close="toggleDeleteGroupModal"
      @accept="deleteGroup"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ModalTaskEdit from "./ModalTaskEdit";
import ModalTaskDetail from "./ModalTaskDetail";
import { InlineEdit } from "@/components/index";
import { tasksApi } from "@/common/api";
import userHelper from "../../common/userHelper.js";
import EventBus from "../../eventBus";
import GeneralModal from "../Modals/GeneralModal";
import { errorHandling } from "../../services/ErrorService";

export default {
  order: 0,
  components: {
    draggable,
    ModalTaskEdit,
    ModalTaskDetail,
    InlineEdit,
    GeneralModal,
  },
  data() {
    return {
      allAssignees: [],
      currentAssignee: null,
      tasksGroups: [],
      showOnlyDoneTasks: false,
      dragging: false,
      modalActive: false,
      currentTask: null,
      currentTasksGroupIdx: null,
      newGroupName: "",
      lockUpdate: false,
      loadedAssigneeFromLocalStorage: false,
      user: userHelper.getUser() || {},
      showDeleteGroupModal: false,
    };
  },
  props: {
    include_closebutton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    readOnly: function () {
      return (
        this.showOnlyDoneTasks ||
        (this.user.role !== "coach" && this.user.role !== "superadmin")
      );
    },
  },
  methods: {
    openModal(task, groupIdx) {
      this.modalActive = true;
      this.currentTask = task;
      this.currentTasksGroupIdx = groupIdx;
    },
    deleteCurrentTask() {
      let currentGroup = this.tasksGroups[this.currentTasksGroupIdx];
      currentGroup.tasks = currentGroup.tasks.filter(
        (task) => task !== this.currentTask
      );
      this.modalActive = false;
      this.currentTask = null;
    },
    markCurrentTaskAsDone() {
      this.tasksGroups[this.currentTasksGroupIdx].doneTasks.push(
        this.currentTask
      );
      this.deleteCurrentTask();
    },
    async createNewTasksGroup() {
      const newTasksGroup = {
        assignee: this.currentAssignee._id,
        name: this.newGroupName,
        tasks: [],
        doneTasks: [],
      };

      await tasksApi
        .createTasksGroup(newTasksGroup)
        .then((res) => {
          if (res && res.data && res.data.tasksGroup) {
            this.tasksGroups.unshift(res.data.tasksGroup);
            this.currentTasksGroupIdx = 0;
            this.newGroupName = "";
          } else {
            this.$toast.warning("could not save tasks group");
          }
        })
        .catch((e) => {
          errorHandling(e);
        });
    },
    toggleDeleteGroupModal(idx) {
      this.currentDeleteGroupId = idx;
      this.showDeleteGroupModal = !this.showDeleteGroupModal;
    },
    async deleteGroup() {
      this.currentTasksGroupIdx = null;
      const groupId = this.tasksGroups[this.currentDeleteGroupId]._id;
      this.tasksGroups.splice(this.currentDeleteGroupId, 1);
      await tasksApi.deleteTasksGroup(groupId).catch((e) => {
        errorHandling(e);
      });
      this.toggleDeleteGroupModal();
    },
    createNewTask(groupIdx) {
      this.currentTasksGroupIdx = groupIdx;
      let newTask = {
        taskTitle: "Task",
        taskContent: "",
        author: userHelper.getUser(),
        date: new Date(),
      };
      this.tasksGroups[groupIdx].tasks.push(newTask);
      this.currentTask = newTask;
      this.modalActive = true;
    },
    getTasksToShowFromGroup(groupIdx) {
      if (this.showOnlyDoneTasks) {
        return this.tasksGroups[groupIdx].doneTasks;
      } else {
        return this.tasksGroups[groupIdx].tasks;
      }
    },
    async updateTasksGroup(groupIdx) {
      this.lockUpdate = true;
      try {
        const currentGroup = this.tasksGroups[groupIdx];
        const res = await tasksApi.updateTasksGroup(
          currentGroup,
          currentGroup._id
        );
        if (!res || !res.data || !res.data.tasksGroup) {
          this.$toast.warning("could not save tasks group");
        }
      } catch (err) {
        console.error(err);
      }
      setTimeout(() => (this.lockUpdate = false), 500);
    },

    updateCurrentTasksGroup() {
      if (this.currentTasksGroupIdx === null) return;
      this.updateTasksGroup(this.currentTasksGroupIdx);
    },

    showTaskListSidebar() {
      EventBus.$emit("showTaskListSidebar");
    },

    loadCurrentAssigneeFromStorage() {
      this.loadedAssigneeFromLocalStorage = true;
      this.currentAssignee = JSON.parse(
        localStorage.getItem("task_list_assignee")
      );
    },

    changeCurrentAssigneeInLocalStorage() {
      localStorage.setItem(
        "task_list_assignee",
        JSON.stringify(this.currentAssignee)
      );
    },

    async fetchTaskDataUserList() {
      await tasksApi
        .getUsers()
        .then((res) => {
          this.allAssignees = res.data;

          if (!this.loadedAssigneeFromLocalStorage) {
            this.loadCurrentAssigneeFromStorage();
          }

          if (this.user.role !== "coach" && this.user.role !== "superadmin") {
            this.currentAssignee = this.allAssignees[0];
          } else if (!this.loadedAssigneeFromLocalStorage) {
            this.currentAssignee = this.allAssignees[0];
          }
        })
        .catch((e) => {
          errorHandling(e);
        });
    },

    async fetchTaskData(user) {
      await tasksApi
        .retrieveTasksGroupForUser(user)
        .then((res) => {
          this.tasksGroups = res.data;
        })
        .catch((e) => {
          errorHandling(e);
        });
    },
  },

  watch: {
    currentAssignee: function () {
      if (this.loadedAssigneeFromLocalStorage) {
        this.changeCurrentAssigneeInLocalStorage();
      }
      if (this.currentAssignee) {
        this.lockUpdate = true;

        setTimeout(() => (this.lockUpdate = false), 1000);
        this.fetchTaskData(this.currentAssignee._id);
      }
    },
    tasksGroups: {
      deep: true,
      handler: function () {
        if (this.lockUpdate) return;
        this.updateCurrentTasksGroup(this.currentTasksGroupIdx);
      },
    },
  },

  created() {
    this.fetchTaskDataUserList();
    this.$auth.$once("user-in-local-storage", () => {
      this.user = userHelper.getUser();
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
