<template>
  <LargeModal
    :classes="'scratchpad-modal  w-100'"
    @close="close()"
    style="min-height: 80vh"
  >
    <template slot="header">
      <h3 class="m-0">
        {{ $t("spreadsheet.spreadsheet_of") }} {{ authorName }}
      </h3>
      <a @click="isFullScreen = true" type="button" class="btn btn-link">
        {{ $t("spreadsheet.full_screen") }}
      </a>
    </template>
    <template slot="body">
      <div class="editorplaceholder" :class="{ 'full-screen': isFullScreen }">
        <span
          class="full-screen-icon m-2 pointer ti-close"
          v-if="isFullScreen"
          @click="isFullScreen = false"
          style="color: black; background-color: #cccccc; font-size: 20px"
        ></span>

        <iframe
          class="iframe"
          height="100%"
          width="100%"
          :src="this.url"
          style="min-height: 70vh"
        ></iframe>
      </div>
    </template>
    <template slot="footer">
      <button class="btn btn-outline-default" @click="close()">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </LargeModal>
</template>

<script>
import LargeModal from "@/components/LargeModal";
import registerCustomColor from "../common/quillBlots";
import { externalDocApi } from "../common/api";
import { errorHandling } from "../services/ErrorService";
export default {
  data() {
    return {
      edited: false,
      isFullScreen: false,
    };
  },
  components: {
    LargeModal,
  },
  props: {
    url: String,
    authorName: String,
    documentId: String,
  },
  methods: {
    close() {
      if (this.edited) {
        this.$emit("update", this.scratchpadContent);
      }
      this.isFullScreen = false;
      this.$emit("close");
    },
    callRegisterCustomColor() {
      registerCustomColor();
    },
    externalDocumentOpenedPing() {
      externalDocApi.externalDocumentOpenedPing(this.documentId).catch((e) => {
        errorHandling(e);
      });
    },
  },
  mounted() {
    this.externalDocumentOpenedPing();
  },
};
</script>
<style lang="scss">
.scratchpad-modal .editorplaceholder {
  min-height: 30rem;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
  }
}
.full-screen-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
