<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row my-4">
        <h3 class="my-auto ml-4 col text-black">
          {{ $t("documents_page.Hello") }} {{ user.name }}.
          {{ $t("documents_page.Welcome_to_Business_Roadmaps_Dashboard") }}
        </h3>
        <a class="nav-link pointer" @click="showDefaultSpreadsheet = true">
          <i class="ti-layout-grid4-alt icon"></i>
          <span> {{ $t("navbar_editor.spreadsheet") }} </span>
        </a>
        <scratchpad
          v-if="user"
          class="nav-link col-md-auto my-auto"
          :authorId="user._id"
          :authorName="user.name"
        />
        <router-link
          class="nav-link"
          :to="{ name: 'settings' }"
          v-if="$route.name === 'documents'"
        >
          <h4 class="col-md-auto text-gray my-auto">
            <i class="ti-settings mr-2" />{{ $t("top_navbar.Settings") }}
          </h4>
        </router-link>
      </div>
    </div>
    <div class="col-md-9" id="docspage">
      <card
        :title="$t('documents_page.new_document_form.Create_a_new_document')"
      >
        <input
          class="form-control border-input"
          v-model="newDocumentTitle"
          :placeholder="$t('documents_page.new_document_form.Enter_the_title')"
        />
        <br />
        <div v-for="(modelTemplate, idx) in modelTemplatesFiltered" :key="idx">
          <input
            type="radio"
            :value="modelTemplate._id"
            @click="newDocumentType = 'BusinessModel'"
            v-model="newDocumentModelTemplate"
          />
          <label class="radiobtnlbl">{{ modelTemplate.name }}</label>
          <br />
        </div>
        <div v-if="user.role != 'StrategyChallenge'">
          <input type="radio" @click="newDocumentType = 'Roadmap'" />
          <label class="radiobtnlbl"> Roadmap </label>
        </div>

        <button class="btn btn-default" @click="createDocument">
          {{ $t("commons.buttons.Add_document") }}
        </button>
      </card>
      <card
        :title="$t('documents_page.documents_list.List_of_documents')"
        class="card card-content"
      >
        <div class="input-group">
          <input
            class="form-control border-input"
            v-model="documentSearchPhrase"
            :placeholder="$t('documents_page.documents_list.Filter_documents')"
          />
          <span
            v-if="documentSearchPhrase"
            class="ti-close my-auto mx-2 pointer"
            @click="documentSearchPhrase = ''"
          />
        </div>
        <div id="tablediv" class="table-full-width table-responsive">
          <table class="table table-hover">
            <thead>
              <th>{{ this.$t("documents_page.documents_list.Title") }}</th>
              <th>{{ this.$t("documents_page.documents_list.Type") }}</th>
              <th>{{ this.$t("documents_page.documents_list.Author") }}</th>
            </thead>
            <tbody>
              <tr
                class="pointer"
                v-for="document in documents"
                :key="document._id"
                @click="editDocument(document)"
              >
                <td>
                  <inline-edit
                    v-model="document.title"
                    @input="editDocumentTitle(document)"
                  >
                    <h4 class="document-title">{{ document.title }}</h4>
                  </inline-edit>
                </td>
                <td>
                  {{
                    document.modelTemplate
                      ? document.modelTemplate.name
                      : document.type
                  }}
                </td>
                <td>{{ document.author.name }}</td>
                <td>
                  <div
                    class="grow"
                    @click.stop="duplicateDocument(document._id)"
                  >
                    {{ $t("commons.buttons.Copy") }}
                    <span
                      class="ti-files icon-button mx-1 hide_in_print my-auto"
                    ></span>
                  </div>
                </td>
                <td>
                  <div
                    class="grow"
                    @click.stop="toggleDeleteDocumentModal(document._id)"
                  >
                    <span
                      class="ti-trash icon-button text-danger mx-1 hide_in_print my-auto"
                    ></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            :records="total"
            v-model="page"
            :per-page="10"
            :options="myPagination"
            @paginate="goToPage"
          />
        </div>
      </card>
    </div>

    <div class="col-md-3" id="coachescard">
      <card
        :title="$t('documents_page.coaches_modal.Your_coaches')"
        v-if="user.role === 'user'"
      >
        <div id="coacheslist">
          <h5 v-for="(coach, index) in selectedCoaches" :key="index">
            {{ coach.name }}
          </h5>
        </div>

        <button
          @click="showCoachesModal = true"
          class="btn btn-default btn-sm m-1"
        >
          {{ $t("commons.buttons.Edit_coach_list") }}
        </button>
      </card>
      <card
        :title="$t('documents_page.collaborators_modal.Your_collaborators')"
        v-if="user.role === 'user'"
      >
        <div id="collabslist">
          <h5 v-for="(collaborator, index) in user.collaborators" :key="index">
            {{ collaborator.name }}
            <span
              @click="toggleRemoveCollaborator(collaborator.email)"
              class="ti-close small pull-right pointer"
            />
          </h5>
        </div>

        <button
          @click="showCollaboratorsModal = true"
          class="btn btn-default btn-sm m-1"
        >
          {{ $t("commons.buttons.Add_collaborator") }}
        </button>
      </card>
      <TaskList />
    </div>
    <modal-coaches
      v-if="showCoachesModal"
      v-model="selectedCoaches"
      :availableCoaches="availableCoaches"
      @close="showCoachesModal = false"
      @select="
        showCoachesModal = false;
        updateCoaches();
      "
    ></modal-coaches>
    <modal-collaborators
      v-if="showCollaboratorsModal"
      @close="showCollaboratorsModal = false"
      @add="updateCollaborators"
    ></modal-collaborators>
    <modal-complete-company-information
      v-if="showCompleteProfileModal"
      @close="showCompleteProfileModal = false"
    />
    <modal-external-document
      v-if="showDefaultSpreadsheet"
      @close="showDefaultSpreadsheet = false"
      :url="spreadsheetURL"
      :author-name="user.name"
      :document-id="externalDocumentId"
    />
    <GeneralModal
      v-if="showDeleteDocumentModal"
      :title="$t('commons.confirms.Confirm_on_delete')"
      :acceptText="$t('commons.buttons.Yes')"
      :cancelText="$t('commons.buttons.No')"
      width="w-25"
      @close="toggleDeleteDocumentModal"
      @accept="deleteDocument"
    />
    <GeneralModal
      v-if="showRemoveCollaboratorModal"
      :title="$t('commons.confirms.Confirm_on_delete')"
      :acceptText="$t('commons.buttons.Yes')"
      :cancelText="$t('commons.buttons.No')"
      width="w-25"
      @close="toggleRemoveCollaborator"
      @accept="removeCollaborator"
    />
  </div>
</template>

<script>
import { TYPE } from "vue-toastification";
import { docAPI, externalDocApi, userApi } from "../common/api";
import userHelper from "../common/userHelper";
import ModalCoaches from "../components/Documents_Page/ModalCoaches";
import ModalCollaborators from "../components/Documents_Page/ModalCollaborators";
import { InlineEdit } from "../components/index";
import TaskList from "../components/Documents_Page/TaskList";
import ModalCompleteCompanyInformation from "@/components/Documents_Page/ModalCompleteCompanyInformation";
import Scratchpad from "@/components/scratchpad";
import ModalExternalDocument from "@/components/ModalExternalDocument";
import GeneralModal from "../components/Modals/GeneralModal";
import MyPagination from "../components/Pagination";
import { errorHandling } from "../services/ErrorService";
import Vue from "vue";

export default {
  components: {
    Scratchpad,
    ModalCompleteCompanyInformation,
    ModalCoaches,
    ModalCollaborators,
    InlineEdit,
    TaskList,
    ModalExternalDocument,
    GeneralModal,
  },

  data() {
    return {
      showCoachesModal: false,
      showCollaboratorsModal: false,
      showCompleteProfileModal: false,
      newDocumentTitle: null,
      newDocumentType: null,
      newDocumentModelTemplate: null,
      documents: [],
      availableCoaches: [],
      selectedCoaches: [],
      documentSearchPhrase: "",
      modelTemplates: [],
      user: userHelper.getUser() || {},
      showDefaultSpreadsheet: false,
      spreadsheetURL: "",
      externalDocumentId: null,
      showDeleteDocumentModal: false,
      selectedDocumentToDelete: "",
      showRemoveCollaboratorModal: false,
      selectedCollaboratorToDelete: "",
      page: 1,
      total: 0,
      limit: 10,
      myPagination: { template: MyPagination },
      debounce: null,
    };
  },
  computed: {
    modelTemplatesFiltered: function () {
      return this.modelTemplates.filter(
        (modelTemplate) => modelTemplate.language === this.$i18n.locale
      );
    },
  },
  watch: {
    documentSearchPhrase: function () {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.page = 1;
        this.getDocuments(1);
      }, 400);
    },
  },

  methods: {
    editDocument(document) {
      if (document.type === "Roadmap") {
        this.$router.push({ name: "roadmap", params: { id: document._id } });
      } else {
        this.$router.push({
          name: "editor",
          params: { id: document._id, type: document.type },
        });
      }
    },

    getDocuments(page, limit = 10) {
      const searchPhrase = this.documentSearchPhrase.toLowerCase();
      docAPI
        .getDocuments(page, limit, searchPhrase)
        .then((response) => {
          this.documents = response.data.documents;
          this.total = response.data.total;
        })
        .catch((e) => {
          errorHandling(e);
        });
    },
    goToPage(page) {
      this.getDocuments(page);
    },
    getAvailableCoaches() {
      userApi
        .getAvailableCoachesList()
        .then((response) => {
          this.availableCoaches = response.data.coaches;
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },

    getModelTemplates() {
      docAPI
        .getModelTemplates()
        .then((response) => {
          this.modelTemplates = response.data;
        })
        .catch((e) => {
          errorHandling(e);
        });
    },

    createDocument() {
      if (
        !this.newDocumentType ||
        !this.newDocumentTitle ||
        (this.newDocumentType === "BusinessModel" &&
          !this.newDocumentModelTemplate) ||
        this.newDocumentTitle.length < 4
      ) {
        this.$toast.warning(
          `${this.$t("notifications.documents_page.Enter_a_unique_name_alert")}`
        );
        return;
      }

      docAPI
        .createDocument(
          this.newDocumentTitle,
          this.newDocumentType,
          this.newDocumentModelTemplate
        )
        .then((response) => {
          this.editDocument(response.data.document);
        })
        .catch((e) => {
          this.$toast(`${e.response.data}`, {
            icon: "ti-alert",
            type: TYPE.ERROR,
          });
        });
    },

    editDocumentTitle(document) {
      docAPI
        .editDocumentTitle(document)
        .then(() => {
          this.getDocuments();
        })
        .catch((e) => {
          errorHandling(e);
        });
    },

    toggleDeleteDocumentModal(docID) {
      this.selectedDocumentToDelete = docID;
      this.showDeleteDocumentModal = !this.showDeleteDocumentModal;
    },

    deleteDocument() {
      docAPI
        .deleteDocument(this.selectedDocumentToDelete)
        .then(() => {
          this.$toast(`${this.$t("notifications.documents_page.Deleted")}`, {
            icon: "ti-trash",
            type: TYPE.WARNING,
          });
          this.toggleDeleteDocumentModal();
          this.getDocuments();
        })
        .catch((e) => {
          errorHandling(e);
        });
    },

    duplicateDocument(docID) {
      docAPI.duplicateDocument(docID).then((response) => {
        docAPI
          .duplicateDocumentVersions(docID, response.data.document._id)
          .then(() => this.getDocuments())
          .catch((e) => {
            errorHandling(e);
          });

        this.$toast(
          `${
            this.$t("notifications.documents_page.Copied") +
            " " +
            this.$t("notifications.documents_page.To") +
            " " +
            response.data.document.title
          }`,
          { icon: "ti-files", type: TYPE.SUCCESS }
        );
      });
    },

    updateCoaches() {
      userApi
        .updateCoaches(this.selectedCoaches)
        .then((response) => {
          this.selectedCoaches = response.data.coaches;
          userHelper.updateUser({ coaches: this.selectedCoaches });
          this.user = userHelper.getUser();
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },

    updateCollaborators(collaborator) {
      this.showCollaboratorsModal = false;
      userApi
        .addCollaborator(collaborator)
        .then((response) => {
          userHelper.updateUser({
            collaborators: response.data.collaborators,
          });
          this.showCollaboratorsModal = false;
          this.user = userHelper.getUser();
        })
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            Vue.$toast.error(
              "The email was not found. The collaborator needs to sign up first to be added."
            );
          } else {
            Vue.$toast.error("Could not add collaborator");
          }
        });
    },

    toggleRemoveCollaborator(collaborator) {
      this.selectedCollaboratorToDelete = collaborator;
      this.showRemoveCollaboratorModal = !this.showRemoveCollaboratorModal;
    },

    removeCollaborator() {
      userApi
        .removeCollaborator(this.selectedCollaboratorToDelete)
        .then((response) => {
          userHelper.updateUser({
            collaborators: response.data.collaborators,
          });
          this.showCollaboratorsModal = false;
          this.user = userHelper.getUser();
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },

    getUser() {
      this.user = userHelper.getUser();
      this.selectedCoaches = this.user.coaches;
      this.showCompleteProfileModal = !userHelper.isProfileComplete();
      const language = this.user.settings.language;
      this.$i18n.locale = language;
      localStorage.setItem("locale", language);
    },

    async loadExternalDocument() {
      let res = await externalDocApi.getExtDocuments();
      this.spreadsheetURL = res.data[0].publicURL;
      this.externalDocumentId = res.data[0]._id;
    },
  },
  created() {
    this.getDocuments();
    this.getAvailableCoaches();
    this.getModelTemplates();
    this.$auth.$once("user-in-local-storage", this.getUser);
    this.getUser();
    this.loadExternalDocument();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
#docspage {
  .document-title {
    float: left;
    display: contents;
  }
  .radiobtnlbl {
    margin-left: 5px;
    margin-bottom: 10px;
  }
  #tablediv {
    margin-left: 0;
  }
}
#coachescard {
  #coacheslist #collabslist {
    margin-top: 1rem;
    margin-left: 1rem;
  }
}
</style>
