<template>
  <card
    :title="$t('SettingsPage.all_users_card.All_users')"
    class="card card-content"
  >
    <div id="tablediv" class="table-responsive overflow-auto">
      <table class="table">
        <thead>
          <th>{{ this.$t("SettingsPage.all_users_card.Name") }}</th>
          <th>{{ this.$t("SettingsPage.all_users_card.Email") }}</th>
          <th>{{ this.$t("SettingsPage.all_users_card.Role") }}</th>
          <th>{{ this.$t("SettingsPage.all_users_card.Coaches") }}</th>
          <th>{{ this.$t("SettingsPage.all_users_card.Company") }}</th>
          <th>{{ this.$t("SettingsPage.all_users_card.Sector") }}</th>
          <th>{{ this.$t("SettingsPage.all_users_card.Address") }}</th>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user._id">
            <td>
              <h5 class="my-auto">
                {{ user.name }}
              </h5>
            </td>
            <td>{{ user.email }}</td>
            <td>
              <template v-if="user.role === 'superadmin'">
                {{ user.role }}
              </template>
              <select
                v-else
                class="form-control border-input pointer"
                @change="updateUserRole(user, $event)"
                :value="user.role"
              >
                <option value="coach">Coach</option>
                <option value="user">User</option>
                <option value="StrategyChallenge">Strategy Challenge</option>
              </select>
            </td>
            <td>
              <span v-for="(coach, idx) in user.coaches" v-bind:key="idx"
                >{{ coach.name }}
              </span>
              <span
                class="ti-pencil icon-button grow mx-2 hide_in_print text-gray my-auto"
                @click.stop="startCoachesModal(user)"
              ></span>
            </td>
            <td>{{ user.company.name }}</td>
            <td>{{ user.company.sector }}</td>
            <td>
              {{ user.company.address.firstLine }} <br />
              {{ user.company.address.postalCode }},
              {{ user.company.address.city }},
              {{ user.company.address.country }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal-coaches
      v-if="showCoachesModal"
      v-model="selectedCoaches"
      :availableCoaches="availableCoaches"
      @close="showCoachesModal = false"
      @select="
        showCoachesModal = false;
        updateCoachesForAnotherUser();
      "
    ></modal-coaches>
  </card>
</template>

<script>
import { userApi } from "@/common/api";
import ModalCoaches from "@/components/Documents_Page/ModalCoaches";
import Vue from "vue";
import { errorHandling } from "../../services/ErrorService";
export default {
  name: "AllUsersCard",
  components: {
    ModalCoaches,
  },
  data() {
    return {
      users: [],
      userForUpdatingCoaches: null,
      showCoachesModal: false,
      availableCoaches: [],
      selectedCoaches: [],
    };
  },
  methods: {
    async getAllUsers() {
      await userApi
        .getAllUsers()
        .then((res) => {
          if (res && res.data && res.data.users) {
            this.users = res.data.users;
          }
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },
    async updateUserRole(user, event) {
      const newRole = event.target.value;
      userApi
        .updateUserRole(user._id, newRole)
        .then(() => {
          this.notifySaved();
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
      this.getAvailableCoaches();
    },

    startCoachesModal(user) {
      this.selectedCoaches = user.coaches;
      this.userForUpdatingCoaches = user;
      this.showCoachesModal = true;
    },

    async updateCoachesForAnotherUser() {
      await userApi
        .updateCoachesForAnotherUser(
          this.userForUpdatingCoaches._id,
          this.selectedCoaches
        )
        .then((res) => {
          this.userForUpdatingCoaches.coaches = res.data.coaches;
          this.notifySaved();
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },

    getAvailableCoaches() {
      userApi
        .getAvailableCoachesList()
        .then((response) => {
          this.availableCoaches = response.data.coaches;
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },

    notifySaved() {
      this.$toast.success(`${this.$t("notifications.Saved")}`, {
        icon: "ti-files",
      });
    },
  },
  created() {
    this.getAllUsers();
    this.getAvailableCoaches();
  },
};
</script>
<style scoped></style>
