import Vue from "vue";
import * as Sentry from "@sentry/vue";

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  Vue: Vue,
  attachProps: true,
  logErrors: true,
});

export function errorHandling(err) {
  if (err instanceof Error) {
    Sentry.captureException(err);
  } else {
    Sentry.captureException(new Error(err));
  }
  console.error(err);
}
