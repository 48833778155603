import { getInstance } from "./authWrapper";
import { PRINT_PARAM } from "@/common/constants";

export const authGuard = (to, from, next) => {
  const isPrinting = window.location.hash.includes(PRINT_PARAM);
  if (isPrinting) {
    return next();
  }
  const authService = getInstance();

  const fn = () => {
    if (!authService.isAuthenticated) {
      authService.loginWithRedirect({
        appState: { targetUrl: to.fullPath },
      });
    } else if (!authService.user.email_verified) {
      return next({ name: "verify email" });
    } else {
      next();
    }
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
