import EditorLayout from "@/layout/dashboard/EditorLayout.vue";
import DocumentsLayout from "@/layout/dashboard/DocumentsLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

import EditorPage from "@/pages/EditorPage.vue";
import GanttPage from "@/pages/GanttPage.vue";
import DocumentsPage from "@/pages/DocumentsPage.vue";
import SettingsPage from "@/pages/SettingsPage.vue";
import TestAuth0Page from "@/pages/TestAuth0Page.vue";
import { authGuard } from "../auth";
import VerifyEmailPage from "@/pages/VerifyEmailPage";

const routes = [
  {
    path: "/",
    redirect: "/documents",
    component: EditorLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: "editor/:type/:id",
        name: "editor",
        component: EditorPage,
      },
      {
        path: "roadmap/:id",
        name: "roadmap",
        component: GanttPage,
      },
    ],
  },
  {
    path: "/",
    component: DocumentsLayout,
    beforeEnter: authGuard,
    children: [
      {
        path: "documents",
        name: "documents",
        component: DocumentsPage,
      },
      {
        path: "settings",
        name: "settings",
        component: SettingsPage,
      },
    ],
  },
  {
    path: "/",
    component: DocumentsLayout,
    children: [
      {
        path: "verify-email",
        name: "verify email",
        component: VerifyEmailPage,
      },
    ],
  },
  {
    path: "/test-auth0",
    name: "test-auth0",
    component: TestAuth0Page,
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
