<template>
  <nav class="navbar navbar-expand-lg top-navbar hide_in_print">
    <div class="container-fluid">
      <a href="https://businessroadmaps.biz/nl/home/">
        <img
          class="navbar-logo"
          src="../../assets/img/business-roadmaps.svg"
          alt="business roadmaps logo"
        />
      </a>
      <div class="navbar-collapse mt-1"></div>

      <button class="btn btn-default" @click="logout()">
        {{ $t("commons.buttons.Logout") }}
      </button>
      <drop-down
        :title="longLanguageName($i18n.locale)"
        class="nav-item ml-3 mt-1"
      >
        <a
          class="dropdown-item"
          v-for="(lang, i) in otherLanguages"
          :key="`Lang${i}`"
          :value="lang"
          @click="setLocale(lang)"
        >
          {{ longLanguageName(lang) }}
          <i class="fa fa-angle-right float-right mt-1"></i>
        </a>
      </drop-down>
    </div>
  </nav>
</template>
<script>
import { userApi } from "../../common/api";
import { errorHandling } from "../../services/ErrorService";

export default {
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    otherLanguages: function () {
      return ["en", "nl"].filter((language) => language != this.$i18n.locale);
    },
  },
  methods: {
    setLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
      userApi.updateSettings({ language: lang }).catch((e) => {
        errorHandling(e);
      });
    },
    logout() {
      this.$auth.logout({ logoutParams: { returnTo: window.location.href } });
    },
    longLanguageName(locale) {
      return {
        en: "English",
        nl: "Nederlands",
      }[locale];
    },
  },
};
</script>
<style>
.ti-arrow-left {
  transition: all 0.1s ease-in-out;
}
.ti-arrow-left:hover {
  transform: scale(1.5);
}
</style>
