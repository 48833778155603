<template>
  <span>
    <template v-if="edit">
      <input
        v-on:click.stop
        v-model="internalValue"
        v-on:keyup.enter="
          $emit('input', internalValue);
          edit = false;
        "
      />
      <span
        class="ti-close icon-button grow mx-2 my-auto"
        @click.stop="
          internalValue = value;
          edit = false;
        "
      ></span>
      <span
        class="ti-check icon-button grow mx-2 my-auto"
        @click.stop="
          $emit('input', internalValue);
          edit = false;
        "
      ></span>
    </template>
    <template v-if="!edit">
      <slot>value</slot>
      <span
        v-if="showPencil"
        class="ti-pencil icon-button grow mx-2 hide_in_print text-gray my-auto"
        :class="iconClass"
        @click.stop="edit = true"
      />
    </template>
  </span>
</template>

<script>
export default {
  name: "InlineEdit",
  props: {
    value: String,
    showPencil: {
      type: Boolean,
      default: true,
    },
    startWithEdit: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      internalValue: this.value,
      edit: this.startWithEdit,
    };
  },
  watch: {
    value: function () {
      this.internalValue = this.value;
    },
  },
};
</script>
