<template>
  <card>
    <h4 slot="header" class="card-title">
      {{ $t("SettingsPage.card_profile_settings.Profile_settings") }}
    </h4>
    <div class="row">
      <div class="col-md-6">
        <fg-input
          type="text"
          :label="$t('SettingsPage.card_profile_settings.Your_name')"
          :placeholder="$t('SettingsPage.card_profile_settings.Your_name')"
          v-model="userName"
        />
      </div>
      <div class="col-md-6">
        <label>{{ $t("SettingsPage.card_profile_settings.Email") }}</label>
        <div class="py-2">{{ userEmail }}</div>
      </div>
    </div>
    <div>
      <button class="btn btn-default" @click="updateProfile">
        {{ $t("commons.buttons.Save") }}
      </button>
    </div>
    <hr />
    <div>
      <button
        class="btn btn-outline-danger"
        @click="$auth.redirectToPasswordReset()"
      >
        {{ $t("SettingsPage.card_profile_settings.Reset_password") }}
      </button>
      <hr />
    </div>
  </card>
</template>
<script>
import userHelper from "@/common/userHelper";
import { userApi } from "@/common/api";
import Vue from "vue";
import { errorHandling } from "../../services/ErrorService";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    const user = userHelper.getUser();
    return {
      userName: user.name,
      userEmail: user.email,
    };
  },
  methods: {
    async updateProfile() {
      await userApi
        .updateProfile({
          name: this.userName,
        })
        .then((res) => {
          userHelper.updateUser({
            name: res.data.user.name,
          });
          this.$toast.success(`${this.$t("notifications.Saved")}`, {
            icon: "ti-files",
          });
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },
  },
};
</script>
