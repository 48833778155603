<template>
  <component
    :is="tag"
    class="dropdown"
    :class="{ show: isOpen }"
    @click="openDropDown"
    @mouseover="openDropDown"
    @mousemove="setToClose = false"
    @mouseleave="closeIfNotOpened"
    v-click-outside="closeDropDown"
  >
    <a
      class="dropdown-toggle btn-rotate"
      :class="titleClasses"
      data-toggle="dropdown"
    >
      <slot name="title">
        <i v-if="icon" :class="icon" class="mx-1"></i>
        <span class="notification"
          >{{ title }}
          <b class="caret"></b>
        </span>
        <i class="fa fa-angle-down fa-dropdown"></i>
      </slot>
    </a>
    <ul class="dropdown-menu dropdown-menu-right" :class="{ show: isOpen }">
      <slot></slot>
    </ul>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      type: String,
      default: "li",
    },
    title: String,
    icon: String,
    titleClasses: [String, Object, Array],
  },
  data() {
    return {
      isOpen: false,
      setToClose: false,
    };
  },
  methods: {
    openDropDown() {
      this.isOpen = true;
      this.setToClose = false;
      this.$emit("change", this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit("change", false);
    },
    closeIfNotOpened() {
      this.setToClose = true;
      setTimeout(() => {
        if (this.setToClose) this.closeDropDown();
      }, 400);
    },
  },
};
</script>
