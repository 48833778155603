import { getInstance } from "./authWrapper";
import { PRINT_PARAM } from "@/common/constants";

export const getToken = () => {
  const isPrinting = window.location.hash.includes(PRINT_PARAM);
  if (isPrinting) {
    return Promise.resolve("dummy_token");
  }
  return new Promise((resolve) => {
    const authService = getInstance();

    if (!authService.loading) {
      resolve(authService.getTokenSilently());
    }

    authService.$watch("loading", async (loading) => {
      if (loading === false) {
        resolve(authService.getTokenSilently());
      }
    });
  });
};
