<template>
  <div class="wrapper-dropdown">
    <span @click="active = !active">
      <span
        v-if="selectedColorName"
        :style="{ background: colorOptions[selectedColorName] }"
      ></span>
      {{
        selectedColorName
          ? selectedColorName
          : $t("gantt_page.gantt_card.task_color.Select_a_color")
      }}
    </span>

    <ul class="dropdown" id="dropdownul" v-show="active">
      <div>
        <li
          v-for="(hex, color) in colorOptions"
          @click="setColor(color)"
          :key="color"
        >
          <span :style="{ background: hex }"></span>
          {{ color }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  model: {
    prop: "selectedColorName",
    event: "colorchange",
  },
  props: {
    colorOptions: Object,
    selectedColorName: String,
  },

  data() {
    return {
      active: false,
    };
  },

  methods: {
    setColor(colorName) {
      this.active = false;
      this.$emit("colorchange", colorName); //Get name instead of hex
    },
  },
};
</script>

<style>
.wrapper-dropdown {
  position: relative;
  background: #fffcf5;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
}
.wrapper-dropdown > span {
  width: 100%;
  display: block;
  border: 1px solid #ababab;
  padding: 5px;
  font-size: 14px;
  color: #66615b;
  transition: background-color 0.3s ease 0s;
  padding: 7px 18px;
  height: 40px;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapper-dropdown > span > span {
  padding: 0 12px;
  margin-right: 5px;
}

.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fffcf5;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border: 1px solid #ababab;
  border-top: 0;
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
  min-height: 28px;
}

#dropdownul {
  position: relative;
  z-index: 102;
}

.wrapper-dropdown .dropdown li > span {
  padding: 0 12px;
  margin-right: 5px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}
</style>
