<template>
  <div>
    <top-navbar-editor
      :is_saving="d$_isSaving"
      :authorId="d$_author._id"
      :authorName="d$_author.name"
      :is_taskList="true"
    />
    <dashboard-content class="pt-2">
      <div class="model-editor">
        <div class="row hide_in_print">
          <h5 class="col-5" v-if="!$_isNewestVersion && $_currentVersionId">
            {{ $t("versions.Version") }}:
            {{ d$_currentVersion.date | formatDate }}.
            <small>{{ $t("versions.Autosave_is_off") }}</small>
            <br />
            <span class="text-info pointer" @click="$_saveAndReload">
              {{ $t("versions.Restore_to_this_version") }}
            </span>
            /
            <span class="text-info pointer" @click="$router.go()">
              {{ $t("versions.Back_to_the_newest_version") }}
            </span>
          </h5>
        </div>
        <div class="row">
          <h3
            :class="[d$_sidebarActive ? 'col-9' : 'col-12']"
            style="text-align: center"
          >
            {{ d$_title }}
          </h3>
        </div>
        <div class="row">
          <div
            class="editor"
            :class="[d$_sidebarActive ? 'col-9' : 'col-12']"
            style="z-index: 0"
          >
            <div id="toolbardiv" class="hide_in_print">
              <editor-toolbar
                class="m-2 editor-toolbar hide_in_print"
                v-for="editorCard in editorCards"
                :editorId="editorCard.id"
                :key="editorCard.id"
              />
              <drop-down
                icon="ti-more btn btn-gray my-auto hide_in_print more-button"
              >
                <a v-if="!isPlainTextLayout" class="dropdown-item">
                  {{ $t("editor_page.dropdown_button.Drag") }}
                  <toggle-button
                    @change="draggable = !draggable"
                    :value="draggable"
                    :sync="true"
                    class="pull-right"
                    :labels="{
                      checked: $t('editor_page.dropdown_button.Drag_on'),
                      unchecked: $t('editor_page.dropdown_button.Drag_off'),
                    }"
                  >
                  </toggle-button>
                </a>
                <a
                  class="dropdown-item"
                  @click="isPlainTextLayout = !isPlainTextLayout"
                  >{{
                    isPlainTextLayout
                      ? $t("editor_page.dropdown_button.Canvas_layout")
                      : $t("editor_page.dropdown_button.Plain_text_layout")
                  }}
                </a>
                <a class="dropdown-item" @click="restoreLayout">{{
                  $t("editor_page.dropdown_button.Restore_to_original_layout")
                }}</a>
                <a class="dropdown-item" @click="copyToClipboard">{{
                  $t("editor_page.dropdown_button.Copy_all_to_clipboard")
                }}</a>
                <a class="dropdown-item" @click="toggleResolveAllCommentsModal">
                  {{ $t("editor_page.dropdown_button.Resolve_all_comments") }}
                </a>
              </drop-down>
              <button
                v-if="$_isNewestVersion"
                @click="enableCommentSidebar"
                class="btn btn-default hide_in_print"
              >
                {{ $t("commons.buttons.Comment") }}
              </button>
            </div>
            <div class="editorsdiv">
              <grid-layout
                :layout.sync="editorCards"
                :col-num="gridRowWidth"
                :row-height="gridRowHeight"
                :is-draggable="draggable"
                :is-resizable="true"
                :is-mirrored="false"
                :vertical-compact="true"
                :responsive="false"
                :use-css-transforms="true"
                :class="{ 'document-view': isPlainTextLayout }"
              >
                <grid-item
                  v-for="(card, index) in editorCards"
                  :x.sync="card.x"
                  :y.sync="card.y"
                  :w.sync="card.w"
                  :h.sync="card.h"
                  :i.sync="card.i"
                  :key="index"
                >
                  <card class="modelcanvascard">
                    <div slot="header">
                      <h4
                        class="card-title text-center pointer"
                        @click="openInformationModal(index)"
                        v-if="cards[index].informationContent"
                      >
                        {{ card.title }}
                        <small class="ml-auto mr-3"
                          ><img
                            class="h-1rem mb-1"
                            src="@/assets/img/infoicon.png"
                        /></small>
                      </h4>
                      <h4 class="card-title text-center" v-else>
                        {{ card.title }}
                      </h4>
                    </div>
                    <editor
                      class="editorcard"
                      ref="editors"
                      :id="card.id"
                    ></editor>
                  </card>
                </grid-item>
              </grid-layout>
            </div>
          </div>
          <div class="col-3 comment-bar">
            <transition name="fade">
              <card v-show="d$_commentSidebarActive">
                <CommentBar
                  @close="d$_sidebarActive = false"
                  ref="commentbar"
                  :commentsToDisplay="d$_currentCommentsThread"
                  :resolved="d$_currentThreadResolved || !$_isNewestVersion"
                  @go-to-version="
                    $_findAndLoadVersionForCurrentCommentsThread()
                  "
                ></CommentBar>
              </card>
            </transition>
            <div v-show="d$_showTaskListSidebar">
              <TaskList :include_closebutton="true" />
            </div>
          </div>
        </div>
      </div>
    </dashboard-content>

    <modal-card-information
      v-if="showInformationModal"
      @close="showInformationModal = false"
      :title="currentInformationCard.title"
      :content="currentInformationCard.informationContent"
    />
    <modal-external-document
      v-if="d$_showDefaultSpreadsheetModal"
      @close="d$_showDefaultSpreadsheetModal = false"
      :url="d$_spreadsheetURL"
      :author-name="d$_author.name"
      :document-id="d$_externalDocumentId"
    />
    <GeneralModal
      v-if="showResolveAllCommentsModal"
      :title="
        $t('editor_page.confirms.Are_you_sure_you_want_to_resolve_all_comments')
      "
      :acceptText="$t('commons.buttons.Yes')"
      :cancelText="$t('commons.buttons.No')"
      width="w-25"
      @close="toggleResolveAllCommentsModal"
      @accept="resolveAllComments"
    />
  </div>
</template>
<script>
import { Card, Editor } from "@/components/index";
import EditorToolbar from "../components/Editor_Page/EditorToolbar.vue";
import CommentBar from "../components/CommentBar.vue";
import EventBus from "../eventBus";
import VueGridLayout from "vue-grid-layout";
import documentEditorMixin from "../mixins/documentEditorMixin";
import Vue from "vue";
import ModalCardInformation from "@/components/Editor_Page/ModalCardInformation";
import TopNavbarEditor from "@/layout/dashboard/TopNavbarEditor";
import DashboardContent from "../layout/dashboard/Content.vue";
import TaskList from "../components/Documents_Page/TaskList";
import ModalExternalDocument from "@/components/ModalExternalDocument";
import GeneralModal from "../components/Modals/GeneralModal";

export default {
  mixins: [documentEditorMixin],
  components: {
    ModalCardInformation,
    TopNavbarEditor,
    Card,
    Editor,
    CommentBar,
    TaskList,
    EditorToolbar,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DashboardContent,
    ModalExternalDocument,
    GeneralModal,
  },
  data() {
    return {
      cards: [],
      docType: null,
      draggable: false,
      editorCards: [],
      gridRowHeight: Math.max(window.innerWidth / 15, 80),
      gridRowWidth: 12,
      isPlainTextLayout: this.$route.query.layout == "true",
      showInformationModal: false,
      currentInformationCard: {},
      showResolveAllCommentsModal: false,
    };
  },

  methods: {
    openInformationModal(index) {
      this.currentInformationCard = this.cards[index];
      this.showInformationModal = true;
    },
    copyToClipboard() {
      let htmlContents = "";
      let textContents = "";
      for (const i in this.$refs.editors) {
        let html = this.$refs.editors[i].editor.container.firstChild.innerHTML;
        let text = this.$refs.editors[i].editor.getText();
        htmlContents += "<h3>" + this.editorCards[i].title + "</h3>" + html;
        textContents += "\n\n" + this.editorCards[i].title + "\n\n" + text;
      }
      function copyToClip() {
        function listener(e) {
          e.clipboardData.setData("text/html", htmlContents);
          e.clipboardData.setData("text/plain", textContents);
          e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);
      }
      copyToClip();
      this.$toast.success(
        `${this.$t("notifications.editor_page.Copied_to_clipboard")}`,
        { icon: "ti-clipboard" }
      );
    },

    openHelp(url) {
      window.open(url, "_blank");
    },

    restoreLayout() {
      for (let i = 0; i < this.editorCards.length; i++) {
        this.editorCards[i].x = this.cards[i].x;
        this.editorCards[i].y = this.cards[i].y;
        this.editorCards[i].w = this.cards[i].w;
        this.editorCards[i].h = this.cards[i].h;
        this.editorCards[i].i = this.cards[i].i;
      }
    },

    enableCommentSidebar() {
      this.d$_currentCommentsThread = null;
      this.d$_currentThreadResolved = false;

      EventBus.$emit("toggleCommentSidebar", {});

      if (this.d$_commentSidebarActive == true) {
        setTimeout(() => {
          this.$refs.commentbar.$refs.commentinput.focus();
        }, 0);
      }
    },

    fetchEditorsContent() {
      for (const i in this.$refs.editors) {
        const editorContent = this.$refs.editors[i].editor.getContents();
        Vue.set(this.editorCards[i], "content", editorContent);
        if (this.d$_autosave) this.$_saveDocument();
      }
    },

    setEditorsContents() {
      for (const i in this.editorCards) {
        if (typeof this.editorCards[i].content === "object") {
          this.$refs.editors[i].editor.setContents(this.editorCards[i].content);
        } else {
          this.$refs.editors[i].editor.clipboard.dangerouslyPasteHTML(
            this.editorCards[i].content
          );
        }
        setTimeout(() => {
          this.editorCards[i].title = this.cards[i].title;
        }, 100);
      }
    },

    resolveCurrentCommentsThread() {
      if (this.d$_currentCommentsThread) {
        EventBus.$emit("resolveCommentBlot", this.d$_currentCommentsThread._id);
      }
    },

    toggleResolveAllCommentsModal() {
      this.showResolveAllCommentsModal = !this.showResolveAllCommentsModal;
    },

    resolveAllComments() {
      EventBus.$emit("resolveAllCommentsClick");
      this.d$_commentSidebarActive = false;
      this.toggleResolveAllCommentsModal();
    },

    focusOnFirstEditor() {
      // by default focus on the first editor
      this.$refs.editors[0].editor.focus();
      this.$refs.editors[0].editor.setSelection(0);
    },
  },
  watch: {
    isPlainTextLayout(newVal) {
      this.$router.push({ query: { ...this.$route.query, layout: newVal } });
    },
    $_currentVersionId: function () {
      this.editorCards = this.d$_currentVersion.content;
      setTimeout(() => {
        this.focusOnFirstEditor();
        this.setEditorsContents();
      }, 50);
    },
    d$_title: function () {
      this.cards = this.d$_modelTemplate.cards;
      const isNewDocument = !this.$_currentVersionId;
      if (isNewDocument) {
        const emptyCards = this.cards.map((card) => {
          let emptyCard = Object.assign(
            {},
            {
              content: "",
              ...card,
            }
          );
          delete emptyCard.informationContent;
          return emptyCard;
        });
        Vue.set(this.d$_currentVersion, "content", emptyCards);
        this.editorCards = this.d$_currentVersion.content;
        setTimeout(() => {
          this.focusOnFirstEditor();
          this.setEditorsContents();
        }, 50);
      }
    },
  },

  created() {
    this.docType = this.$route.params.type.toString();
    window.addEventListener("resize", () => {
      this.gridRowHeight = Math.max(window.innerWidth / 15, 80);
    });
  },

  mounted() {
    EventBus.$on("commentClicked", this.$_showCommentsThread);
    EventBus.$on("resolveCommentsThread", this.resolveCurrentCommentsThread);

    EventBus.$on("activeEditorSelected", () => {
      if (this.d$_commentSidebarActive) this.d$_commentSidebarActive = false;
    });

    EventBus.$on("documentChanged", () => {
      this.fetchEditorsContent();
    });
  },
  beforeDestroy() {
    EventBus.$off("documentChanged");
    EventBus.$off("resolveCommentsThread");
    EventBus.$off("activeEditorSelected");
    EventBus.$off("commentClicked");
  },
};
</script>

<style lang="scss">
.model-editor {
  .save-icon {
    position: fixed;
    right: 2rem;
  }
  .document-view {
    height: auto !important;
    .vue-grid-item {
      width: 100% !important;
      height: auto !important;
      position: relative !important;
      transform: inherit !important;
    }
  }
  #toolbardiv {
    z-index: 10;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;

    .editor-toolbar {
      width: 100%;
    }

    button {
      height: min-content;
      margin: auto;
    }
  }

  .comment-bar .card {
    position: sticky;
    position: -webkit-sticky;
    top: 1rem;
    height: fit-content;
  }
  .editorcard {
    height: 100%;
  }
  .modelcanvascard {
    height: 100%;
  }

  .vue-resizable-handle {
    z-index: 2;
  }

  .card-body {
    height: 85%;
  }

  .more-button {
    padding: 13px;
  }

  .dropdown {
    margin: auto 5px auto auto;
    i.fa-dropdown {
      display: none !important;
    }
    .dropdown-menu {
      background-color: white;
    }
  }

  :window-inactive .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
