import { Quill } from "vue-quill-editor";

export default function () {
  const Inline = Quill.import("blots/inline");

  class CustomColor extends Inline {
    constructor(domNode, value) {
      super(domNode, value);

      // Map <font> properties
      domNode.style.color = domNode.color;

      const span = this.replaceWith(new Inline(Inline.create()));

      span.children.forEach((child) => {
        if (child.attributes) child.attributes.copy(span);
        if (child.unwrap) child.unwrap();
      });

      this.remove();

      return span;
    }
  }

  CustomColor.blotName = "customColor";
  CustomColor.tagName = "FONT";

  Quill.register(CustomColor, true);
  Quill.debug("error");
}
