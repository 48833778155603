<template>
  <div class="wrapper">
    <div class="main-panel min-vh-100 full-width">
      <top-navbar></top-navbar>
      <router-view></router-view>
      <footer-copied />
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import FooterCopied from "@/layout/dashboard/FooterCopied";

export default {
  components: {
    FooterCopied,
    TopNavbar,
  },
};
</script>
<style lang="scss" scoped>
.main-panel.full-width {
  width: 100%;
}

.logo {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  height: 7rem;
  width: auto;
  z-index: -1;
}
</style>
