/*!

 =========================================================
 * Vue Paper Dashboard - v2.0.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App";
import router from "./router/index";
import PaperDashboard from "./plugins/paperDashboard";
import VueWaypoint from "vue-waypoint";
import Toast from "vue-toastification";
import ToggleButton from "vue-js-toggle-button";
import moment from "moment";
import i18n from "./i18n";
import { Auth0Plugin } from "./auth";
import * as VueGoogleMaps from "vue2-google-maps";
import "vue-toastification/dist/index.css";
import Pagination from "vue-pagination-2";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
    libraries: "places",
  },
});

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: (appState) => {
    window.history.replaceState({}, document.title, "/#/");
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VueWaypoint);
Vue.use(require("vue-resource"));
Vue.use(PaperDashboard);
Vue.use(ToggleButton);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true,
  timeout: 4000,
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("MMMM Do YYYY, h:mm a");
  }
});

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("pagination", Pagination);

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
