<template>
  <div class="editorplaceholder">
    <quill-editor
      class="editor"
      ref="myTextEditor"
      :value="content"
      :options="editorOption"
      @change="onEditorChange"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)"
    />
  </div>
</template>

<script>
import dedent from "dedent";
import debounce from "lodash/debounce";
import { quillEditor, Quill } from "vue-quill-editor";
import EventBus from "../../eventBus";
import registerCustomColor from "../../common/quillBlots";
// highlight.js style
import "highlight.js/styles/tomorrow.css";
// import theme style
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  name: "Editor",
  components: {
    quillEditor,
  },
  props: {
    id: Number,
  },

  data() {
    return {
      lastIndex: null,

      editorOption: {
        placeholder: this.$t("editor_page.editor_cards.Insert_text_here"),
        modules: {
          toolbar: "#toolbar" + this.id,
        },
      },

      content: dedent``,
    };
  },
  methods: {
    onEditorChange: debounce(function (value) {
      this.content = value.html;
      EventBus.$emit("documentChanged");
    }, 466),

    onEditorFocus() {
      EventBus.$emit("freezeindex");
      EventBus.$emit("activeEditorSelected", this.id);
    },

    onEditorReady() {
      registerCustomColor();

      let BlotEmbed = Quill.import("blots/embed");

      class CommentBlot extends BlotEmbed {
        static create(value) {
          let commentID = value.commentID;
          const node = super.create();

          node.dataset.commentID = commentID; //write into html
          node.setAttribute("class", "ti-comment text-success pointer");

          node.addEventListener("click", () => {
            EventBus.$emit("commentClicked", node.dataset.commentID, false);
            node.setAttribute("class", "ti-comment text-success annotation");
          });

          EventBus.$on("resolveCommentBlot", (threadid) => {
            if (threadid == commentID) node.parentNode.removeChild(node);
          });

          EventBus.$once("resolveAllCommentsClick", () =>
            node.parentNode.removeChild(node)
          );

          return node;
        }
        static value(node) {
          return { commentID: node.dataset.commentID };
        }

        length() {
          return 1;
        }

        deleteAt() {
          return;
        }
      }

      CommentBlot.blotName = "comment";
      CommentBlot.tagName = "span";
      CommentBlot.className = "annotation";

      Quill.register({
        "formats/comment": CommentBlot,
      });
    },

    insertCommentIcon(id) {
      this.editor.insertEmbed(this.lastIndex, "comment", { commentID: id });
    },
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
  },

  mounted() {
    //create a new one
    EventBus.$on("commentsThreadCreated", (commentid) => {
      if (
        this.lastIndex ||
        this.lastIndex === 0 ||
        this.editor.getSelection()
      ) {
        this.insertCommentIcon(commentid);
      }
    });

    EventBus.$on("freezeindex", () => {
      if (this.editor) {
        if (this.editor.getSelection()) {
          this.lastIndex = this.editor.getSelection().index;
        } else {
          this.lastIndex = null;
        }
      }
    });
  },

  beforeDestroy() {
    EventBus.$off("resolveAllCommentsClick");
    EventBus.$off("resolveCommentBlot");
    EventBus.$off("freezeindex");
    EventBus.$off("commentsThreadCreated");
  },
};
</script>

<style lang="scss">
.editorplaceholder {
  .editor {
    height: 100%;
  }
}
</style>
