<template>
  <div id="ganttcomponent">
    <gantt
      :scale="timescaleunit"
      :groupScale="grouptimescaleunits"
      :calculate="false"
      :events-list="gantt"
      :end-period="moment(end)"
      :start-period="moment(startPeriod)"
      @load-more="loadMore"
      @moveTask="moveTaskByName"
      @removeTask="removeTaskByName"
      @editTask="prefillTaskFormForEdit"
      @left-click-selected="resetTaskForm()"
      @edit-initial-grouping="$emit('edit-initial-grouping', $event)"
      :active="activeChart === chartIx"
      :status-colors="statusColors"
      :chartIx="chartIx"
      :initial-grouping="initialGrouping"
    />

    <transition name="fade">
      <div
        v-show="activeChart === chartIx"
        id="ganttForm"
        class="row hide_in_print"
      >
        <div class="col-md-2">
          <label class="control-label">{{
            $t("gantt_page.gantt_card.Name_of_the_task")
          }}</label>
          <input
            class="form-control border-input"
            type="text"
            :placeholder="$t('gantt_page.gantt_card.Type_your_task')"
            v-model="newTaskName"
          />
        </div>
        <div class="col-md-2" id="outercolordiv">
          <label class="control-label">{{
            $t("gantt_page.gantt_card.task_color.Task_color")
          }}</label>
          <div id="color-picker">
            <color-picker-single
              :colorOptions="this.statusColors"
              v-model="selectedColorName"
            ></color-picker-single>
          </div>
        </div>
        <div class="col-md-2">
          <label class="control-label">{{
            $t("gantt_page.gantt_card.task_repetition.Task_repetition")
          }}</label>
          <select class="form-control border-input" v-model="repeatsSelected">
            <option value="once">
              {{ $t("gantt_page.gantt_card.task_repetition.No") }}
            </option>
            <option value="monthly">
              {{ $t("gantt_page.gantt_card.task_repetition.Monthly") }}
            </option>
            <option value="quarterly">
              {{ $t("gantt_page.gantt_card.task_repetition.Quarterly") }}
            </option>
            <option value="yearly">
              {{ $t("gantt_page.gantt_card.task_repetition.Yearly") }}
            </option>
          </select>
        </div>

        <div class="col-md-2">
          <label class="control-label">{{
            $t("gantt_page.gantt_card.Task_group")
          }}</label>
          <input
            class="form-control border-input"
            type="text"
            v-model="taskGroupNameSelected"
            :list="'taskGroupNames-' + chartIx"
            :placeholder="$t('gantt_page.gantt_card.Enter_name')"
          />
          <datalist :id="'taskGroupNames-' + chartIx">
            <option v-for="group in taskGroupNames" :key="group">
              {{ group }}
            </option>
          </datalist>
        </div>

        <div class="col-md-2">
          <label class="control-label">{{
            $t("gantt_page.gantt_card.Task_weight")
          }}</label>
          <input
            class="form-control border-input"
            type="number"
            min="10"
            max="100"
            v-model="taskWeightSelected"
            list="taskWeightValues"
            :placeholder="$t('gantt_page.gantt_card.Weight')"
          />
          <datalist id="taskWeightValues">
            <option v-for="weight in taskWeightValues" :key="weight">
              {{ weight }}
            </option>
          </datalist>
        </div>

        <div class="col-md-2 row update-buttons">
          <div>
            <button
              type="submit"
              class="btn m-2 btn-default addtaskbtn"
              v-on:click.prevent="addOrEditTask"
            >
              {{ submitButtonText }}
            </button>
          </div>
          <div v-if="isEditing">
            <input
              type="reset"
              class="btn m-2 btn-outline-danger canceltaskbtn"
              :value="$t('commons.buttons.Cancel')"
              v-on:click.prevent="resetTaskForm"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Gantt from "./Gantt";
import ColorPickerSingle from "./ColorPickerSingle";

export default {
  props: {
    chartIx: Number,
    goalName: String,
    ganttData: Array,
    colors: Array,
    timescaleunit: String,
    grouptimescaleunits: Number,
    startPeriod: String,
    endPeriod: String,
    activeChart: Number,
    initialGrouping: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  components: {
    Gantt,
    ColorPickerSingle,
  },

  data() {
    return {
      moment,
      newTaskName: null,
      selectedColorName: "",
      repeatsSelected: "once",
      taskGroupNameSelected: "",
      taskWeightValues: [10, 20, 30, 40, 50, 60],
      taskWeightSelected: null,
      taskToEditIdx: null,
      gantt: null,
      end: null,
    };
  },
  computed: {
    statusColors: function () {
      let statusColors = {};
      this.colors.map((color) => {
        statusColors[color.name.toLowerCase()] = color.hex;
      });
      return statusColors;
    },
    taskGroupNames: function () {
      let uniqueGroupNames = this.gantt
        .map((chart) => chart.group_by)
        .filter((group, idx, groupNames) => groupNames.indexOf(group) === idx);
      return uniqueGroupNames;
    },
    isEditing: function () {
      return this.taskToEditIdx != null;
    },
    submitButtonText: function () {
      return this.isEditing
        ? this.$t("commons.buttons.Update")
        : this.$t("commons.buttons.Add_task");
    },
  },
  created() {
    this.gantt = this.ganttData;
    this.end = this.endPeriod;
  },
  watch: {
    activeChart: function (newActiveChart, oldActiveChart) {
      if (oldActiveChart == this.chartIx && newActiveChart != this.chartIx) {
        this.resetTaskForm();
      }
    },
  },

  methods: {
    loadMore() {
      this.end = moment(this.end).add(30, "days");
    },

    isFormValid() {
      if (!this.newTaskName || this.newTaskName.length < 4) {
        this.$toast.warning("Choose a longer name for the task");
        return false;
      }

      let isNewTaskNameUnique = () => {
        const existingNames = this.ganttData.map((a) => a.title.toLowerCase());
        if (this.taskToEditIdx) existingNames.splice(this.taskToEditIdx, 1);
        return existingNames.indexOf(this.newTaskName.toLowerCase()) == -1;
      };

      if (!isNewTaskNameUnique()) {
        this.$toast.warning(
          "Choose a different name for the task (this task name already exists in the chart)"
        );

        return false;
      }

      if (!this.selectedColorName || this.selectedColorName.length < 1) {
        this.$toast.warning(
          `${this.$t("notifications.gantt_page.Select_a_color_for_the_task")}`
        );
        return false;
      }
      return true;
    },

    getLastIndexOfGroup(group) {
      let indexFromEnd = this.gantt
        .slice()
        .reverse()
        .findIndex((task) => task.group_by === group);
      return this.gantt.length - 1 - indexFromEnd;
    },

    getFirstIndexOfGroup(group) {
      return this.gantt.findIndex((task) => task.group_by === group);
    },

    findIndexForNewTask(groupName) {
      let existingGroup = this.gantt
        .map((task) => task.group_by)
        .find((group) => group === groupName);
      if (existingGroup != undefined) {
        return this.getLastIndexOfGroup(existingGroup) + 1;
      } else {
        return this.getFirstIndexOfGroup("");
      }
    },

    addOrEditTask() {
      if (!this.isFormValid()) return;

      let task, newIndex;
      if (this.isEditing) {
        task = this.gantt.splice(this.taskToEditIdx, 1)[0];

        if (task.group_by != this.taskGroupNameSelected) {
          newIndex = this.findIndexForNewTask(this.taskGroupNameSelected);
        } else {
          newIndex = this.taskToEditIdx;
        }
      } else {
        let generateNewTaskId = () => Math.random().toString(36).substr(2, 9);

        task = {
          id: generateNewTaskId(),
          offset: 1,
          duration: 3,
          x: 0,
          width: 0,
        };
        newIndex = this.findIndexForNewTask(this.taskGroupNameSelected);
      }

      task.status = this.selectedColorName;
      task.group_by = this.taskGroupNameSelected;
      task.weight = this.taskWeightSelected;
      task.frequency = { key: this.repeatsSelected };
      task.title = this.newTaskName;

      this.gantt.splice(newIndex, 0, task);
      this.resetTaskForm();
    },

    resetTaskForm() {
      this.taskToEditIdx = null;
      this.newTaskName = null;
      this.taskGroupNameSelected = "";
      this.repeatsSelected = "once";
      this.taskWeightSelected = null;
      this.selectedColorName = "";
    },

    getTaskIxByName(name) {
      for (const i in this.gantt) {
        if (this.gantt[i].title === name) {
          return i;
        }
      }
    },

    moveElementInGanttData(from, to) {
      this.gantt.splice(to, 0, this.gantt.splice(from, 1)[0]);
    },

    prefillTaskFormForEdit(taskName) {
      this.taskToEditIdx = this.getTaskIxByName(taskName);
      let taskToEdit = this.gantt[this.taskToEditIdx];

      this.newTaskName = taskToEdit.title;
      this.selectedColorName = taskToEdit.status;
      this.repeatsSelected = taskToEdit.frequency.key;
      this.taskGroupNameSelected = taskToEdit.group_by;
      this.taskWeightSelected = taskToEdit.weight;
    },

    removeTaskByName(taskName) {
      this.gantt.splice(this.getTaskIxByName(taskName), 1);
      this.resetTaskForm();
    },

    moveTaskByName(taskName, direction) {
      let oldTaskIx = parseInt(this.getTaskIxByName(taskName));
      let groupName = this.gantt[oldTaskIx].group_by;
      let groupStartIx = this.getFirstIndexOfGroup(groupName);
      let groupEndIx = this.getLastIndexOfGroup(groupName);

      if (direction === "up" && groupStartIx < oldTaskIx) {
        this.moveElementInGantt(oldTaskIx, oldTaskIx - 1);
      }
      if (direction === "down" && groupEndIx > oldTaskIx) {
        this.moveElementInGantt(oldTaskIx, oldTaskIx + 1);
      }
      this.taskToEditIdx = this.getTaskIxByName(taskName);
    },
  },
};
</script>

<style lang="scss" scoped>
#ganttcomponent {
  #goaltitle {
    margin: 0 0 0;
  }

  // disables arrow next to the datalist inputs
  input::-webkit-calendar-picker-indicator {
    display: none;
  }

  .update-buttons {
    padding-top: 1rem;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #292929;
}
</style>
