<template>
  <modal
    :title="$t('documents_page.collaborators_modal.Add_a_collaborator')"
    @close="$emit('close')"
  >
    <template slot="body">
      <input
        class="form-control border-input"
        v-model="newCollaborator"
        :placeholder="
          $t('documents_page.collaborators_modal.Enter_collaborators_email')
        "
      />
    </template>
    <template slot="footer">
      <button class="btn btn-default" @click="$emit('add', newCollaborator)">
        {{ $t("commons.buttons.Add") }}
      </button>
      <button class="btn btn-outline-default" @click="$emit('close')">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "../Modal";

export default {
  data() {
    return {
      newCollaborator: null,
    };
  },
  components: {
    Modal,
  },
};
</script>
