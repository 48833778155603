<template>
  <modal
    classes="w-50"
    @close="refresh()"
    :title="$t('VerifyEmailPage.verify_your_email')"
  >
    <template slot="body">
      {{ $t("VerifyEmailPage.A_verification_email_was_sent_to_you_text") }}
    </template>
    <template slot="footer">
      <button class="btn btn-outline-default" @click="logout()">
        {{ $t("commons.buttons.Logout") }}
      </button>
      <button class="btn btn-outline-success" @click="refresh()">
        {{ $t("VerifyEmailPage.verified") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal,
  },
  methods: {
    refresh() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({ logoutParams: { returnTo: window.location.href } });
    },
  },
};
</script>

<style></style>
