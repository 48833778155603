<template>
  <CollapsableButtonMenu v-on="$listeners" :values="buttons" />
</template>

<script>
import CollapsableButtonMenu from "./CollapsableButtonMenu";
export default {
  components: {
    CollapsableButtonMenu,
  },
  data() {
    return {
      buttons: [
        {
          emit: "delete",
          icon: "ti-trash",
          isToggled: false,
          class: "",
        },
        {
          emit: "down",
          icon: "ti-angle-double-down",
          isToggled: false,
          class: "",
        },
        {
          emit: "up",
          icon: "ti-angle-double-up",
          isToggled: false,
          class: "",
        },
        {
          emit: "edit",
          icon: "ti-pencil",
          isToggled: true,
          main: true,
          class: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss"></style>
