<template>
  <div class="wrapper-dropdown">
    <span @click="active = !active">
      <span v-if="selectedGroupHexes.length === 0">{{
        $t("gantt_page.create_gantt.Select_colors")
      }}</span>
      <span
        v-else
        v-for="(color, colorIx) in selectedGroupHexes"
        :style="{ background: color }"
        :key="colorIx"
      >
      </span>
    </span>
    <ul class="dropdown" id="dropdownul" v-show="active">
      <li
        v-for="(group, groupIx) in colorOptions"
        @click="setColorGroup(group)"
        :key="groupIx"
      >
        <span
          v-for="(color, colorIx) in group"
          :style="{ background: color.hex }"
          :key="colorIx"
        ></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  model: {
    prop: "selectedGroupFull",
    event: "groupchange",
  },

  props: {
    colorOptions: Array,
    selectedGroupFull: Array,
  },

  data() {
    return {
      active: false,
      selectedGroupHexes: [],
    };
  },

  methods: {
    setColorGroup(group) {
      this.active = false;
      this.selectedGroupHexes = group.map((a) => a.hex);
      this.$emit("groupchange", group);
    },
  },
};
</script>

<style>
.wrapper-dropdown {
  position: relative;
  background: #fffcf5;
  color: #2e2e2e;
  outline: none;
  cursor: pointer;
}
.wrapper-dropdown > span {
  width: 100%;
  display: block;
  border: 1px solid #ababab;
  padding: 5px;
  font-size: 14px;
  color: #66615b;
  transition: background-color 0.3s ease 0s;
  padding: 7px 18px;
  height: 40px;
  box-shadow: none;
}
.wrapper-dropdown > span > span {
  padding: 0 12px;
  margin-right: 5px;
}

.wrapper-dropdown > span:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 16px;
  top: calc(50% + 4px);
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #2e2e2e transparent;
}

.wrapper-dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fffcf5;
  font-weight: normal;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  border: 1px solid #ababab;
  border-top: 0;
}

.wrapper-dropdown .dropdown li {
  display: block;
  text-decoration: none;
  color: #2e2e2e;
  padding: 5px;
  cursor: pointer;
  min-height: 28px;
}

#dropdownul {
  position: relative;
  z-index: 102;
}

.wrapper-dropdown .dropdown li > span {
  padding: 0 12px;
  margin-right: 5px;
}

.wrapper-dropdown .dropdown li:hover {
  background: #eee;
  cursor: pointer;
}
</style>
