<template>
  <modal :classes="'scratchpad-modal  w-100'" @close="close()">
    <template slot="header">
      <h3 class="m-0">{{ $t("scratchpad.Scratchpad_of") }} {{ authorName }}</h3>
      <a @click="isFullScreen = true" type="button" class="btn btn-link">
        {{ $t("scratchpad.full_screen") }}
      </a>
    </template>
    <template slot="body">
      <div class="editorplaceholder" :class="{ 'full-screen': isFullScreen }">
        <span
          class="full-screen-icon m-2 pointer ti-close"
          v-if="isFullScreen"
          @click="isFullScreen = false"
        ></span>

        <quill-editor
          :value="scratchpadContent"
          @input="
            $emit('update', $event);
            edited = true;
          "
          @ready="callRegisterCustomColor()"
          class="editor"
        />
      </div>
    </template>
    <template slot="footer">
      <button class="btn btn-outline-default" @click="close()">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import { quillEditor } from "vue-quill-editor";
import registerCustomColor from "../common/quillBlots";

export default {
  data() {
    return {
      edited: false,
      isFullScreen: false,
    };
  },
  components: {
    Modal,
    quillEditor,
  },
  props: {
    scratchpadContent: String,
    authorName: String,
  },
  methods: {
    close() {
      if (this.edited) {
        this.$emit("update", this.scratchpadContent);
      }
      this.isFullScreen = false;
      this.$emit("close");
    },
    callRegisterCustomColor() {
      registerCustomColor();
    },
  },
};
</script>
<style lang="scss">
.scratchpad-modal .editorplaceholder {
  min-height: 10rem;
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
  }
}
.full-screen-icon {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
