<template>
  <div>
    <div id="adpcalendar">
      <div id="timeline-sidebar">
        <div
          v-for="(group, index) in groupByData"
          :key="index"
          :style="{
            height: `${
              group.show ? group.height + getTopMargin : getTopMargin
            }px`,
          }"
        >
          <div
            v-if="showGrouping"
            class="category-header-wrapper"
            :style="{ height: `${blockHeight}px` }"
          >
            <slot name="category-header" :data="group">
              <div class="category-header" @click="group.show = !group.show">
                <inline-edit
                  :value="group.title"
                  @input="updateGroupName(group.title, $event)"
                  :showPencil="active"
                >
                  <span> {{ group.title }} ({{ group.events.length }}) </span>
                  <span
                    class="ti-angle-double-down icon-button grow mx-2 text-gray"
                    v-if="active"
                    @click.stop="moveGroupDown(index)"
                  />
                  <span
                    class="ti-angle-double-up icon-button grow text-gray"
                    @click.stop="moveGroupUp(index)"
                    v-if="active"
                  />
                </inline-edit>
                <span v-if="group.show" class="caret collapsed ml-auto">
                  &rsaquo;
                </span>
                <span v-else class="caret ml-auto">&rsaquo;</span>
              </div>
            </slot>
          </div>
          <svg
            id="event-types"
            ref="svg"
            :width="titleWidth"
            :height="group.height"
            v-if="group.show"
          >
            <g class="rows">
              <rect
                v-for="(event, $index) in group.events"
                x="0"
                :y="blockHeight * $index"
                width="100%"
                :height="blockHeight"
                stroke="#f5f5f5"
                stroke-width="2"
                :key="$index"
              />
            </g>
            <g v-for="(event, eventIndex) in group.events" :key="eventIndex">
              <title>{{ event.title }}</title>
              <text
                @click="select(event.title)"
                text-anchor="right"
                :x="15"
                :y="blockHeight * eventIndex + 5 + blockHeight / 2"
              >
                {{ event.title | truncate(52) }}
              </text>
              <svg x="140" :y="blockHeight * eventIndex + blockHeight / 2 - 16">
                <g>
                  <foreignObject width="150px" height="40px" v-if="active">
                    <GanttTaskEditingMenu
                      v-on:edit="$emit('editTask', event.title)"
                      v-on:delete="$emit('removeTask', event.title)"
                      v-on:down="$emit('moveTask', event.title, 'down')"
                      v-on:up="$emit('moveTask', event.title, 'up')"
                      style="float: right"
                    />
                  </foreignObject>
                </g>
              </svg>
            </g>
          </svg>
        </div>
      </div>

      <div
        id="timeline-container"
        ref="container"
        @mousemove="move"
        @mouseup="mouseUp"
      >
        <div
          id="timeline"
          class="timeline"
          ref="timeline"
          :style="{ 'min-height': `${groupByData.length * blockHeight}px` }"
        >
          <div id="timeline-dates" :style="{ width: `${svgWidth}px` }">
            <svg
              id="timeline-dates-svg"
              ref="svg"
              :width="svgWidth"
              :height="22"
            >
              <g>
                <g>
                  <g class="titles">
                    <g v-for="(line, $index) in gridLines" :key="$index">
                      <g v-if="$index % smartGrids === 1">
                        <text
                          text-anchor="middle"
                          :x="($index - 1) * hourWidth + titleWidth + 30"
                          y="10"
                        >
                          {{ line }}
                        </text>
                      </g>
                    </g>

                    <foreignObject
                      :x="svgWidth - 500"
                      width="1"
                      height="100%"
                      v-if="inifinteScroll"
                    >
                      <v-waypoint @waypoint="collide" :horizontal="true" />
                    </foreignObject>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div
            v-for="(group, rootIndex) in groupByData"
            :key="rootIndex"
            :style="{
              height: `${
                group.show ? group.height + getTopMargin : getTopMargin
              }px`,
            }"
          >
            <svg
              ref="svg"
              id="timeline-events"
              :width="svgWidth"
              :height="group.show ? group.height + getTopMargin : 0"
              v-show="group.show"
            >
              <g>
                <g>
                  <rect
                    @click="group.show = false"
                    x="0"
                    :y="0"
                    width="100%"
                    fill="transparent"
                    :height="blockHeight"
                    stroke="#eceaef"
                    stroke-width="2"
                  />
                </g>
                <g class="rows">
                  <rect
                    v-for="(event, $index) in group.events"
                    x="0"
                    :y="blockHeight * $index + getTopMargin"
                    width="100%"
                    :height="blockHeight"
                    stroke="#f5f5f5"
                    stroke-width="2"
                    :key="$index"
                  />
                </g>

                <g class="graph">
                  <foreignObject width="100%" height="100%">
                    <div
                      class="grid-pattern"
                      :style="[
                        gridPatternStyles,
                        { height: `${group.height}px` },
                      ]"
                    />
                  </foreignObject>

                  <g class="paths">
                    <path
                      v-for="(link, pathIndex) in linkPaths[rootIndex]"
                      :d="link.path"
                      :class="{ critical: link.critical }"
                      :key="pathIndex"
                    />
                  </g>

                  <g class="blocks">
                    <g
                      class="block"
                      v-for="(event, eventIndex) in group.events"
                      :key="eventIndex"
                    >
                      <title>{{ event.title }}</title>

                      <rect
                        @click="select(event)"
                        @mousedown="adjustStart(event, $event)"
                        rx="2"
                        ry="2"
                        :x="event.x + 30"
                        :y="event.y"
                        :width="event.width"
                        :height="event.height"
                        :class="{ editable: !readOnly && !event.readOnly }"
                        :style="{ fill: event.label }"
                      >
                        <title v-if="event.readOnly">
                          🔒{{ event.readOnly }}
                        </title>
                        <title v-else>{{ event.title }}</title>
                      </rect>
                      <text
                        v-if="event.readOnly"
                        :x="event.x + event.width / 2"
                        :y="event.y + 2 * (event.height / 3)"
                        class="icon"
                        text-anchor="middle"
                      >
                        🔒
                      </text>

                      <rect
                        v-if="!readOnly && !event.readOnly"
                        class="drag-handle"
                        @mousedown.prevent="adjustEnd(event, $event)"
                        rx="5"
                        ry="5"
                        :x="event.x + event.width - 10 + 30"
                        :y="event.y"
                        width="10"
                        :height="event.height"
                        fill="#ccc"
                      />
                      <template v-if="showRepeats">
                        <rect
                          v-for="(child, childIndex) in event.children"
                          rx="2"
                          ry="2"
                          :x="child.x + 30"
                          :y="child.y"
                          :width="child.width"
                          :height="child.height"
                          class="repeat"
                          :style="{ fill: child.label }"
                          :key="childIndex"
                        >
                          <title>{{ child.title }}</title>
                        </rect>
                      </template>
                      <text
                        v-if="event.weight"
                        id="overlayBarText"
                        :x="event.x + 10 + 30"
                        :y="event.y + 15"
                        font-size="25"
                      >
                        {{ event.weight }}
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div
              v-show="!group.show"
              class="closed-bar"
              :style="{ width: `${svgWidth}px`, height: `${blockHeight}px` }"
              @click="group.show = !group.show"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { cloneDeep } from "lodash";
import GanttTaskEditingMenu from "./GanttTaskEditingMenu";
import { InlineEdit } from "@/components/index";

export default {
  props: {
    scale: {
      default: "weeks",
    },
    groupScale: {
      default: 3,
    },
    endPeriod: {
      default() {
        return moment().add(1, "months");
      },
    },
    startPeriod: {
      default() {
        return moment().startOf("week");
      },
    },
    calculate: {
      default: false,
    },
    selected: {
      twoWay: true,
    },
    autoScale: {
      default: false,
    },
    criticalPath: {
      default: true,
    },
    active: {
      default: false,
    },
    eventsList: {
      default() {
        return [];
      },
    },
    initialGrouping: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showGrouping: {
      default: true,
    },
    inifinteScroll: {
      default: false,
    },
    grow: {
      default: false,
    },
    showRepeats: {
      default: true,
    },

    statusColors: null,

    readOnly: {
      type: Boolean,
      default: false,
    },

    chartIx: {
      type: Number,
    },
  },

  components: {
    GanttTaskEditingMenu,
    InlineEdit,
  },

  data() {
    return {
      links: [],
      hourWidth: 29.1,
      blockHeight: 35,
      scaleWidth: 29.1,
      titleWidth: 0,
      topMargin: 35,
      clonedSelectedEvent: null,
      groupByData: [],
    };
  },

  computed: {
    ungroupedName() {
      return this.$t("gantt_page.gantt_card.Ungrouped");
    },

    svgWidth() {
      if (this.autoScale) {
        return "100%";
      }
      if (!this.hourWidth || !this.limits.range) return 0;

      return this.hourWidth * this.limits.range + 200;
    },

    linkPaths() {
      const links = [];
      this.groupByData.forEach((group) => {
        links.push(
          group.links.map((link) => {
            const startX = link[0].x + link[0].width / 2;
            const startY = link[0].y + link[0].height;
            const laneTop = link[1].y;
            const endX = link[1].x;
            const endY = link[1].y + this.blockHeight / 3;
            link.path = `M${startX} ${startY}
                                    L ${startX} ${laneTop}
                                    a 12 12 0 0 0 12 12
                                    L ${endX} ${endY}
                                    M ${endX - 10} ${endY - 7}
                                    L ${endX} ${endY}
                                    L ${endX - 10} ${endY + 7}`;
            link.critical = link[2] ? link[2] : false;
            return link;
          })
        );
      });

      return links;
    },

    smartGrids() {
      return this.groupScale;
    },

    gridDateFormat() {
      if (this.scale === "hours") {
        return "Do MMM HH:mm";
      }
      return "Do MMM YY";
    },

    gridLines() {
      if (this.limits.range) {
        return Array(...Array(this.limits.range)).map((a, i) =>
          moment(this.limits.start)
            .add(i, this.scale)
            .format(this.gridDateFormat)
        );
      }
      return [];
    },

    limits() {
      const limits = {
        start: this.startPeriod,
        actualStart: this.startPeriod,
        end: this.endPeriod,
        range: 0,
        units: this.scale,
      };

      this.eventsList.map((event) => {
        if (
          this.grow &&
          (limits.end === false || moment(event.end).isAfter(limits.end))
        ) {
          limits.end = moment(event.end).endOf("week");
        }
      });

      limits.range = Math.ceil(limits.end.diff(limits.start, this.scale));

      return limits;
    },

    globalOffset() {
      return this.limits.actualStart.diff(this.limits.start, "days") - 1;
    },

    eventsWithRepeats() {
      const repetitions = {
        daily: { interval: 1, unit: "days" },
        every_work_day: { interval: 1, unit: "every_work_day" },
        weekly: { interval: 1, unit: "weeks" },
        four_weekly: { interval: 4, unit: "weeks" },
        fortnightly: { interval: 2, unit: "days" },
        monthly: { interval: 1, unit: "months" },
        quarterly: { interval: 1, unit: "quarters" },
        yearly: { interval: 1, unit: "years" },
      };

      return cloneDeep(this.eventsList).map((event) => {
        const frequency = event.frequency.key;
        event.label = this.statusColors[event.status];

        if (Object.keys(repetitions).indexOf(frequency) == -1) {
          event.children = [];
          return event;
        }
        const unit = repetitions[frequency].unit;
        const interval = repetitions[frequency].interval;

        const nUnits = Math.round(this.limits.end.diff(event.start, unit));

        let childEvents = [];
        for (let i = interval; i <= nUnits; i += interval) {
          const newEvent = cloneDeep(event);
          newEvent.repeat = true;
          newEvent.start = moment(newEvent.start).add(i, unit);
          newEvent.end = moment(newEvent.end).add(i, unit);

          if (frequency === "every_work_day") {
            while (newEvent.start.day() === 0 || newEvent.start.day() === 6) {
              newEvent.start.add(1, "days");
              newEvent.end.add(1, "days");
              i += 1;
            }
          }

          newEvent.offset = moment(this.limits.start)
            .add(newEvent.offset, this.scale)
            .add(i, unit)
            .diff(moment(this.limits.start), this.scale);
          childEvents.push(newEvent);
        }
        event.children = childEvents;
        return event;
      });
    },

    getTopMargin() {
      return this.showGrouping ? this.topMargin : 0;
    },

    gridPatternStyles() {
      return {
        marginTop: `${this.getTopMargin}px`,
        marginLeft: `${this.titleWidth + 30}px`,
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='${
          this.scaleWidth * this.smartGrids
        }' height='100' viewBox='0 0 ${
          this.scaleWidth * this.smartGrids
        } 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M0 0 H 5 V 100 H 0 Z m${
          this.scaleWidth
        } 0 h 1 V 100 h -1 Z m${this.scaleWidth} 0 h 1 V 100 h -1 Z m${
          this.scaleWidth
        } 0 h 1 V 100 h -1 Z m${this.scaleWidth} 0 h 1 V 100 h -1 Z m${
          this.scaleWidth
        } 0 h 1 V 100 h -1 Z m${
          this.scaleWidth
        } 0 h 1 V 100 h -1 Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      };
    },
  },

  filters: {
    truncate(val, length) {
      if (val.length < length) {
        return val;
      }
      return `${val.substring(0, length - 3)}...`;
    },
  },

  methods: {
    buildGroupByData() {
      const position = this.calculate ? this.calculatedPosition : this.position;

      const links = { [this.ungroupedName]: [] };
      let initialGrouping = {};

      this.initialGrouping.map((groupName) => {
        initialGrouping[groupName] = [];
      });
      const processNodes = this.eventsWithRepeats.reduce(
        (grouped, event, i, array, groupName = event.group_by) => {
          const displayedGroupName = groupName ? groupName : this.ungroupedName;

          grouped[displayedGroupName] = grouped[displayedGroupName] || [];

          let group = grouped[displayedGroupName];

          if (group.indexOf(event) === -1) group.push(event);
          event.event_index = this.showGrouping ? group.indexOf(event) : i;

          if (this.showRepeats && event.children && event.children.length) {
            event.children.map((child) => {
              child.event_index = event.event_index;
              position(child);
            });
          }

          if (event.dependencies) {
            links[displayedGroupName] = links[displayedGroupName] || [];
            event.dependencies.map((dep) => {
              links[displayedGroupName].push([this.eventsList[dep], event]);
            });
          }

          position(event);

          return grouped;
        },
        initialGrouping
      );

      Object.keys(processNodes).map((groupName) => {
        if (!processNodes[groupName].length) delete processNodes[groupName];
      });

      const clonedGroupByData = cloneDeep(this.groupByData);

      this.groupByData = Object.keys(processNodes).map((groupName) => {
        const wasHiddenBefore = clonedGroupByData.some((group) => {
          return group.title == groupName && !group.show;
        });
        return {
          title: groupName,
          links: links[groupName] || [],
          events: processNodes[groupName],
          show: !wasHiddenBefore,
          height: processNodes[groupName].length * this.blockHeight,
        };
      });
    },

    collide(e) {
      this.$emit("load-more", e);
    },

    calculatedPosition(event) {
      event.x =
        moment(event.start).diff(this.limits.start, this.scale) *
        this.scaleWidth;
      event.width =
        (moment(event.end).diff(event.start, this.scale) + 1) * this.scaleWidth;
      event.height = this.blockHeight - 15;
      event.y = event.event_index * this.blockHeight + 7.5;
      return event;
    },

    position(event) {
      event.page = event.page || 1;
      event.x =
        (event.offset + this.globalOffset + (event.page - 1) * 30) *
          this.scaleWidth +
        this.titleWidth;
      event.width = event.duration * this.scaleWidth;
      event.height = this.blockHeight - 15;
      event.y = event.event_index * this.blockHeight + 7.5 + this.getTopMargin;
      return event;
    },

    select(event) {
      this.$emit("left-click-selected", event);
      if (this.readOnly) {
        this.$emit("selected", event);
      }
    },

    updateGroupName(oldName, newName) {
      if (oldName == this.ungroupedName) {
        oldName = "";
      }
      if (newName == this.ungroupedName) {
        newName = "";
      }
      this.eventsList.map((event) => {
        if (event.group_by == oldName) {
          this.$set(event, "group_by", newName);
        }
      });
    },

    updateInitialGrouping() {
      let allGroups = [
        ...new Set(
          this.eventsList.map((event) => event.group_by || this.ungroupedName)
        ),
      ];
      let newInitialGrouping = allGroups
        .sort((a, b) => {
          return (
            this.initialGrouping.indexOf(a) - this.initialGrouping.indexOf(b)
          );
        })
        .sort((a, b) => {
          return a == this.ungroupedName ? -1 : b == this.ungroupedName ? 1 : 0;
        });

      this.$emit("edit-initial-grouping", newInitialGrouping);
    },

    moveGroupUp(index) {
      if (index <= 1 && this.initialGrouping[0] === this.ungroupedName) return;
      const newInitialGrouping = this.initialGrouping.slice();
      newInitialGrouping.splice(
        index - 1,
        0,
        newInitialGrouping.splice(index, 1)[0]
      );
      this.$emit("edit-initial-grouping", newInitialGrouping);
    },

    moveGroupDown(index) {
      if (index == 0 && this.initialGrouping[0] === this.ungroupedName) return;
      const newInitialGrouping = this.initialGrouping.slice();
      newInitialGrouping.splice(
        index + 1,
        0,
        newInitialGrouping.splice(index, 1)[0]
      );
      this.$emit("edit-initial-grouping", newInitialGrouping);
    },

    adjustStart(event, evt) {
      if (evt.which === 3 || this.readOnly || event.readOnly) return;

      this.startMouse = evt;
      this.dragging = "start";
      this.clonedSelectedEvent = cloneDeep(event);
    },

    adjustEnd(event, evt) {
      if (evt.which === 3 || this.readOnly || event.readOnly) return;
      this.startMouse = evt;
      this.dragging = "end";
      this.clonedSelectedEvent = cloneDeep(event);
    },

    move(evt) {
      if (!this.dragging) return;

      const diff = Math.round(
        (evt.clientX - this.startMouse.clientX) / this.scaleWidth
      );

      if (Math.abs(diff)) {
        const position = this.calculate
          ? this.calculatedPosition
          : this.position;

        if (this.dragging === "start") {
          this.clonedSelectedEvent.offset += diff;
          this.clonedSelectedEvent.starts_at = moment(
            this.clonedSelectedEvent.starts_at
          )
            .add(diff, "days")
            .format("YYYY-MM-DD");
        } else {
          this.clonedSelectedEvent.duration += diff;
        }

        this.clonedSelectedEvent.ends_at = moment(
          this.clonedSelectedEvent.ends_at
        )
          .add(diff, "days")
          .format("YYYY-MM-DD");
        position(this.clonedSelectedEvent);

        this.saveCloned();

        this.startMouse = {
          clientX: this.startMouse.clientX + diff * this.scaleWidth,
        };
      }
    },

    mouseUp(evt) {
      if (!this.dragging || evt.which === 3) return;

      this.dragging = false;
    },

    saveCloned() {
      let selectedEvent = this.eventsList.find((event) => {
        return event.id === this.clonedSelectedEvent.id;
      });
      this.$set(selectedEvent, "offset", this.clonedSelectedEvent.offset);
      this.$set(selectedEvent, "starts_at", this.clonedSelectedEvent.starts_at);
      this.$set(selectedEvent, "duration", this.clonedSelectedEvent.duration);
      this.$set(selectedEvent, "x", this.clonedSelectedEvent.x);
      this.$set(selectedEvent, "width", this.clonedSelectedEvent.width);
    },
  },

  mounted() {
    this.buildGroupByData();
    this.updateInitialGrouping();
  },

  watch: {
    initialGrouping: "buildGroupByData",
    eventsList: {
      handler: "updateInitialGrouping",
      deep: true,
    },
    scale: "buildGroupByData",
  },
};
</script>

<style lang="scss" scoped>
$header-border-size: 1px;
$header-border-colour: #eceaef;
$header-caret-colour: #666666;
$row-border: solid $header-border-size $header-border-colour;
$white: #ffffff;

$notification-confirm: #ffeb3b;

.rows rect {
  fill: $white;
}
.rows rect:nth-child(even) {
  fill: #f5f5f5;
}

.blocks rect {
  fill: #c6dafc;
  transition: all 300ms;
}

.blocks rect.repeat {
  opacity: 0.4;
}
.blocks rect.drag-handle {
  fill: #ccc;
  cursor: ew-resize;
  opacity: 0;
}
.block:hover .editable {
  stroke: #ddd;
  stroke-width: 3px;
  cursor: pointer;
}
.block:hover rect.drag-handle {
  opacity: 1;
}

path {
  fill: none;
  stroke: #000;
  stroke-width: 1.8px;
  transition: all 200ms;
}

text {
  cursor: default;
  -webkit-user-select: none;
  -webkit-font-smoothing: antialiased;
  font-family: Arial;
  font-size: 13px;
  text-transform: capitalize;
}

#timeline-sidebar {
  padding-top: 35px;
  overflow: hidden;
  border-right: 5px solid rgba(200, 200, 200, 1);
  min-width: 300px;
}

#timeline-container {
  overflow-x: auto;
  margin-top: 0;
}

#timeline-dates {
  display: flex;
  margin-top: 13px;
}

#timeline {
  width: 100%;
  display: block;
  padding-bottom: 10px;
  overflow-x: scroll;
}
#event-types {
  width: 300px;
  flex: 0 0 auto;
}
#timeline-content {
  flex: 1 1 auto;
  overflow: auto;
}
#timeline-events {
  overflow: visible;
  overflow-x: scroll;
}
#timeline-index {
  height: 30px;
}
#adpcalendar {
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
}

.category-header-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: $row-border;
}

.category-header {
  display: flex;
  align-items: center;
  padding: 0 10px;

  font-weight: 800;
  text-transform: capitalize;

  cursor: pointer;

  .title {
    flex-grow: 1;
  }

  .caret {
    flex-grow: 0;
    font-size: 2rem;
    color: $header-caret-colour;
    transform: scale(1, 1.3);

    &.collapsed {
      transform: scale(1.3, 1) rotate(90deg);
    }
  }
}

.closed-bar {
  background-color: #f5f5f5;
  border: $row-border;
}

.grid-pattern {
  width: 100%;
}

text.icon {
  font-family: Icons;
  color: $white;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
</style>
