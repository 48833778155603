<template>
  <div>
    <div class="row w-100 mt-4">
      <div class="col-md-6">
        <router-link :to="{ name: 'documents' }" class="ml-4">
          <span class="ti-arrow-left text-dark" />
          <h4 class="d-inline text-gray">
            {{ $t("commons.buttons.Back_to_dashboard") }}
          </h4>
        </router-link>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-6">
        <card-profile-settings />
        <card>
          <h4 slot="header" class="card-title">
            {{ $t("SettingsPage.Company_information") }}
          </h4>
          <form-company-information />
          <hr />
        </card>
      </div>
      <div class="col-md-6">
        <card-settings-notifications />
      </div>
      <div class="col-md-12" v-if="user.role === 'superadmin'">
        <card-invitation />

        <all-users-card style="z-index: 0; position: relative" />
      </div>
    </div>
  </div>
</template>

<script>
import CardSettingsNotifications from "@/components/SettingsPage/CardSettingsNotifications";
import userHelper from "@/common/userHelper";
import AllUsersCard from "@/components/SettingsPage/AllUsersCard";
import FormCompanyInformation from "@/components/SettingsPage/FormCompanyInformation";
import CardProfileSettings from "@/components/SettingsPage/CardProfileSettings";
import CardInvitation from "@/components/SettingsPage/CardInvitation";

export default {
  components: {
    CardProfileSettings,
    FormCompanyInformation,
    CardSettingsNotifications,
    AllUsersCard,
    CardInvitation,
  },
  data() {
    return {
      user: userHelper.getUser(),
    };
  },
};
</script>

<style></style>
