<template>
  <modal classes="task-modal w-75" @close="$emit('close')" :title="taskTitle">
    <template slot="body">
      <quill-editor :value="taskContent" ref="editor" :options="quillOptions" />
    </template>
    <template slot="footer">
      <span class="my-auto mr-auto">
        {{ $t("documents_page.tasks_list.task_for") }} {{ assigneeName }},
        {{ $t("documents_page.tasks_list.created_by") }} {{ author }},
        {{ date | formatDate }}
      </span>
      <button class="btn btn-outline-default" @click="$emit('close')">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "../Modal";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    Modal,
    quillEditor,
  },
  props: {
    taskTitle: String,
    taskContent: String,
    assigneeName: String,
    date: Date,
    author: String,
  },
  data() {
    return {
      quillOptions: { modules: { toolbar: false } },
    };
  },
  mounted() {
    this.$refs.editor.quill.disable();
  },
};
</script>
<style lang="scss">
.task-modal .ql-editor {
  min-height: 10rem;
  position: relative;
  z-index: 1000;
}
</style>
