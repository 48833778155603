<template>
  <div>
    <button @click="callApi">Call token check api</button>
    <p>{{ apiMessage }}</p>
    <button v-if="$auth.isAuthenticated" @click="logout()">Logout</button>
    <button v-else @click="login()">Login</button>
    <p>{{ $auth.user }}</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "external-api",
  data() {
    return {
      apiMessage: "",
    };
  },
  methods: {
    async callApi() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      // Use Axios to make a call to the API
      const { data } = await axios.get("http://localhost:3000/check-token", {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });

      this.apiMessage = data;
    },
    logout() {
      this.$auth.logout({ logoutParams: { returnTo: window.location.href } });
    },
    login() {
      this.$auth.loginWithRedirect({
        appState: { targetUrl: this.$route.path },
      });
    },
  },
};
</script>
