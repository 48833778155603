<template>
  <div
    @click="toggle"
    v-click-outside="close"
    class="task-editor-buttons hide_in_print mt-1"
  >
    <div
      v-for="(btn, i) in toggledButtons"
      :key="i"
      @click="btn.main ? $emit(btn.emit) : emit(btn.emit)"
      class="icon-button my-auto mx-1"
      :class="btn.class"
    >
      <span :title="btn.emit.toUpperCase()" :class="btn.icon"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      default: [],
    },
  },

  methods: {
    toggle() {
      for (let btn of this.values) {
        if (!btn.main) btn.isToggled = true;
      }
    },

    close() {
      for (let btn of this.values) {
        if (!btn.main) btn.isToggled = false;
      }
    },

    emit(event) {
      this.$emit(event);
      setTimeout(() => {
        this.close();
      }, 200);
    },
  },

  computed: {
    toggledButtons: function () {
      return this.values.filter((b) => {
        return b.isToggled;
      });
    },
  },
};
</script>

<style lang="scss">
.task-editor-buttons {
  display: flex;
  button {
    float: right;
    margin-right: 5px !important;
  }
}
</style>
