<template>
  <card>
    <h4 slot="header" class="card-title">
      {{ $t("SettingsPage.notification_settings.Notification_settings") }}
    </h4>
    <div class="card-content">
      <div>
        <div class="col-md-6 px-0">
          <label class="control-label">{{
            $t("SettingsPage.notification_settings.set_frequency.Set_frequency")
          }}</label>
          <select
            class="form-control border-input"
            v-model="notificationFrequency"
          >
            <option value="instantly">
              {{
                $t("SettingsPage.notification_settings.set_frequency.Instantly")
              }}
            </option>
            <option value="daily">
              {{ $t("SettingsPage.notification_settings.set_frequency.Daily") }}
            </option>
            <option value="weekly">
              {{
                $t("SettingsPage.notification_settings.set_frequency.Weekly")
              }}
            </option>
            <option value="no notifications">
              {{
                $t(
                  "SettingsPage.notification_settings.set_frequency.No_notifications"
                )
              }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <div>
        <button
          class="btn btn-default"
          @click="updateNotificationFrequencySettings"
        >
          {{ $t("commons.buttons.Save") }}
        </button>
      </div>
    </div>
    <hr />
  </card>
</template>
<script>
import { userApi } from "../../common/api";
import userHelper from "../../common/userHelper.js";
import Vue from "vue";
import { errorHandling } from "../../services/ErrorService";

export default {
  data() {
    return {
      notificationFrequency:
        userHelper.getUser().settings.notificationFrequency,
      role: userHelper.getUser().role,
    };
  },
  methods: {
    updateNotificationFrequencySettings() {
      userApi
        .updateSettings({
          notificationFrequency: this.notificationFrequency,
        })
        .then((response) => {
          let settings = response.data.settings;
          if (settings) {
            userHelper.updateUser({ settings });
            this.$toast.success(`${this.$t("notifications.Saved")}`, {
              icon: "ti-files",
            });
          }
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },
  },
};
</script>
