<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <label>{{
          $t("SettingsPage.form_company_information.Company_name")
        }}</label>
        <gmap-autocomplete
          class="form-control border-input"
          ref="gmapAutocomplete"
          @place_changed="setPlace"
          @input="name = $event.target.value"
          :placeholder="
            $t('SettingsPage.form_company_information.Company_name')
          "
          :value="name"
          :types="['establishment']"
        />
      </div>
      <div class="col-md-6">
        <fg-input
          type="text"
          :label="$t('SettingsPage.form_company_information.Sector')"
          :placeholder="$t('SettingsPage.form_company_information.Sector')"
          v-model="sector"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>{{
          $t("SettingsPage.form_company_information.Billing_address")
        }}</label>
        <gmap-autocomplete
          class="form-control border-input mb-3"
          ref="gmapAutocomplete"
          @place_changed="setPlace"
          @input="address.firstLine = $event.target.value"
          :placeholder="
            $t('SettingsPage.form_company_information.Adress_first_line')
          "
          local
          :value="address.firstLine"
          :types="['address']"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <fg-input
          type="text"
          :label="$t('SettingsPage.form_company_information.Postal_code')"
          :placeholder="$t('SettingsPage.form_company_information.Postal_code')"
          v-model="address.postalCode"
        >
        </fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          type="text"
          :label="$t('SettingsPage.form_company_information.City')"
          :placeholder="$t('SettingsPage.form_company_information.City')"
          v-model="address.city"
        >
        </fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          type="text"
          :label="$t('SettingsPage.form_company_information.Country')"
          :placeholder="$t('SettingsPage.form_company_information.Country')"
          v-model="address.country"
        >
        </fg-input>
      </div>
    </div>
    <div>
      <button class="btn btn-default" @click="updateCompanyInformation">
        {{ $t("commons.buttons.Save") }}
      </button>
    </div>
  </div>
</template>
<script>
import userHelper from "@/common/userHelper";
import { userApi } from "@/common/api";
import Vue from "vue";
import { errorHandling } from "../../services/ErrorService";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: "",
      sector: "",
      address: {
        firstLine: "",
        city: "",
        country: "",
        postalCode: "",
      },
    };
  },
  methods: {
    setPlace(place) {
      if (!place.address_components) {
        return;
      }
      const isEstablishmentPlace = Boolean(place["business_status"]);
      if (isEstablishmentPlace && place.name) {
        this.name = place.name;

        if (Array.isArray(place.types) && place.types.length > 2) {
          this.sector = place.types[0];
        }
      }

      let addressDict = {};
      place.address_components.map((component) => {
        const addressType = component.types[0];
        addressDict[addressType] = component["long_name"];
      });

      let street = "";
      let street_number = "";
      if (addressDict["street_number"])
        street_number = addressDict["street_number"];
      if (addressDict["route"]) street = addressDict["route"];
      if (addressDict["locality"]) this.address.city = addressDict["locality"];
      if (addressDict["country"]) this.address.country = addressDict["country"];
      if (addressDict["postal_code"])
        this.address.postalCode = addressDict["postal_code"];
      this.address.firstLine = street + " " + street_number;
    },
    async updateCompanyInformation() {
      await userApi
        .updateCompanyInformation({
          name: this.name,
          sector: this.sector,
          address: this.address,
        })
        .then((res) => {
          userHelper.updateUser({ company: res.data.company });
          this.$emit("save");
          this.$toast.success(`${this.$t("notifications.Saved")}`, {
            icon: "ti-files",
          });
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },

    updateCompanyDataFromUser() {
      const company = userHelper.getUser().company;
      if (!company) {
        return;
      }
      this.name = company.name;
      this.sector = company.sector;
      this.address.firstLine = company.address.firstLine;
      this.address.city = company.address.city;
      this.address.country = company.address.country;
      this.address.postalCode = company.address.postalCode;
    },
  },

  created() {
    this.updateCompanyDataFromUser();
    this.$auth.$once("user-in-local-storage", this.updateCompanyDataFromUser);
  },
};
</script>
