<template>
  <modal
    classes="modal-info w-75 text-black"
    @close="$emit('close')"
    title="Roadmap"
  >
    <template slot="body">
      <template v-if="$i18n.locale === 'nl'">
        <p>
          <span style="background-color: transparent"
            >De roadmap verbindt alle voorgaande stappen. Het verbindt, aan de
            ene kant, het Business en het Strategie Model met, aan de andere
            kant, de ambitie (BHAG) en het Organisatie Model.&nbsp;</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="background-color: transparent"
            >Een opmerking vooraf: neem in de titel van ieder blok duidelijk op
            wat de cijfers in het blok voorstellen. Bijvoorbeeld het aantal
            nieuwe klanten per maand achter de titel van een taak, uren per
            maand in de tijdlijn, …&nbsp;</span
          >
        </p>
        <p><br /></p>
        <p>
          <span style="background-color: transparent"
            >Bouw de Roadmap op de volgende manier op:</span
          >
        </p>
        <ul>
          <li>
            <span style="background-color: transparent"
              >Begin met een blok waarin je je huidige tijdsbesteding (in grote
              categorieën) plaatst</span
            >
          </li>
          <li>
            <span style="background-color: transparent"
              >Ontwikkel dan in een volgend blok je verkoopritme. Het
              verkoopritme bepaalt in welk tempo je naar je ambitie toegroeit.
              Zorg dat goed zichtbaar is hoe je verkoopritme zich ontwikkeld.
              Bij voorkeur op de volgende manier:</span
            >
          </li>
          <li class="ql-indent-1">
            <span style="background-color: transparent"
              >Zet achter de naam van een actie het aantal waarin je groeitempo
              zich ontwikkelt, bijvoorbeeld hoeveel nieuwe klanten je per maand
              verwacht.&nbsp;</span
            >
          </li>
          <li class="ql-indent-1">
            <span style="background-color: transparent"
              >Zet in de tijdlijn het aantal uren dat je aan die actie
              besteedt.</span
            >
          </li>
          <li>
            <span style="background-color: transparent"
              >Als het blok met het verkoopritme klaar is, ga je blokken met
              activiteiten ontwikkelen die moeten bijdragen aan het realiseren
              van het verkoopritme. Zoals acquisitie, marketing, pr, interne
              organisatie, doorontwikkeling product/dienst, enzovoort.</span
            >
          </li>
          <li>
            <span style="background-color: transparent"
              >Plan de acties bij de ondersteunende activiteiten zo in dat ze
              telkens vóór een groeisprong (een nieuwe groeifase, een sprong in
              je verkooprimte) plaatsvinden. Je moet tijdig intensiveren (in
              marketing, acquisitie, interne organisatie) om uiteindelijk een
              groeisprong mogelijk te kunnen maken.</span
            >
          </li>
          <li>
            <span style="background-color: transparent"
              >Het is verstandig om eerst je doelenboom te ontwikkelen. De
              doelenboom bevat alle blokken met doelen (huidige activiteiten,
              verkoopritme, marketing / sales, …) en acties die eronder vallen.
              Belangrijk is om dan ook al bij het verkoopritme aantallen (achter
              de naam van de actie) te plaatsen, zodat je verkoopritme ook in
              aantallen zichtbaar is. Als die doelenboom klaar is, ga dan de
              tijdlijnen plaatsen met capaciteiten (uren, fte, …) erin. Dan
              voorkom je dat je bij iedere aanpassing in de doelenboom alle
              tijdlijnen capaciteiten opnieuw moet gaan inplannen.</span
            >
          </li>
        </ul>
        <p><br /></p>
        <p>
          <span style="background-color: transparent"
            >Hieronder staat een voorbeeld van een roadmap.</span
          >
        </p>
        <p class="ql-align-center"><br /></p>
        <p class="ql-align-center">
          <span style="background-color: transparent"
            ><img
              src="https://lh3.googleusercontent.com/eDOI-PY-70YNscUB-q0SLEPsT4dV4mYgJVbD5vNrd4I6w2nJH8aeRLd-ZSt2MmlbdHtYQpJ-Ew5J-h9fto4BLt81_vUyrCAYrNAyYnUErwu-vwnb8MHYW9tBuQ7-dX22l54_qfGo"
              height="416"
              width="694"
          /></span>
        </p>
        <p class="ql-align-center"><br /></p>
      </template>
      <template v-if="$i18n.locale === 'en'">
        <p>
          The roadmap connects all previous steps. It connects, on the one hand,
          the Business and the Strategy Model with, on the other hand, the
          ambition (BHAG) and the Organization Model.
        </p>
        <p><br /></p>
        <p>
          A preliminary note: clearly state in the title of each block what the
          numbers in the block represent. For example, the number of new
          customers per month behind the title of a task, hours per month in the
          timeline, ...
        </p>
        <p><br /></p>
        <p>Build the Roadmap in the following way:</p>
        <ul>
          <li>
            Start with a block in which you place your current time use (in
            large categories)
          </li>
          <li>
            Then, develop your sales rhythm in the next block. The sales rhythm
            determines the pace at which you grow towards your ambition. Make
            sure it is clearly visible how your sales rhythm is developing.
            Preferably in the following way:
          </li>
          <li>
            Enter the number of hours you spend on that action in the timeline.
          </li>
          <li>
            When the block with the sales rhythm is finished, you will develop
            blocks with activities that should contribute to realizing the sales
            rhythm. Such as acquisition, marketing, PR, internal organization,
            further development of product / service, and so on.
          </li>
          <li>
            Plan the actions for the support activities so that they always take
            place before a growth leap (a new growth phase, a leap in your sales
            space). You have to intensify in time (in marketing, acquisition,
            internal organization) in order to ultimately be able to make a
            growth leap.
          </li>
          <li>
            It is wise to develop your goal tree first. The goal tree contains
            all blocks with goals (current activities, sales rhythm, marketing /
            sales,…) and actions that fall under it. It is therefore important
            to add numbers to the sales rhythm (behind the name of the
            promotion), so that your sales rhythm is visible in numbers. When
            that goal tree is ready, place the timelines with capacities (hours,
            FTE, ...) in it. This way, you avoid having to reschedule all
            timeline capacities for every adjustment in the goal tree.
          </li>
        </ul>
        <p><br /></p>
        <p>Below you will find an example of a roadmap:</p>
        <p><br /></p>
        <p class="ql-align-center">
          <span style="background-color: transparent"
            ><img
              src="https://lh6.googleusercontent.com/WhAMJAa0BJi54Ki7A428UdfKrp87V7woTgs7hkGs1GmP1rhqRETN3h4GE6pTM76XbveFdoqd_Xz4xAfu_m5QOldSUSQCwNT5v9dfWQ6BKpeJmSfX-a32pRKfUF2ZAnE13z3upPJv"
              height="361"
              width="602"
          /></span>
        </p>
      </template>
    </template>
    <template slot="footer">
      <button class="btn btn-outline-default" @click="$emit('close')">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "../Modal";

export default {
  components: {
    Modal,
  },
};
</script>
<style lang="scss">
.modal-info .ql-editor {
  min-height: 10rem;
}
</style>
