import userHelper from "@/common/userHelper";
import { backendClient } from "./backend.client";

export const editorApi = {
  saveDocument(content, documentId) {
    return backendClient.post("/createDocumentVersion/", {
      content: content,
      date: new Date(),
      documentId: documentId,
    });
  },

  getDocumentWithCommentsAndVersion(docID) {
    return backendClient.get(`/getDocumentWithCommentsAndVersion/` + docID);
  },

  createNewCommentsThread(docID) {
    return backendClient.post("/createCommentsThread", {
      documentId: docID,
    });
  },

  addComment(commentThreadID, comment) {
    let user = userHelper.getUser();
    return backendClient.post(`/addComment/` + commentThreadID, {
      text: comment,
      author_email: user.email,
      date: new Date(),
      author: user._id,
    });
  },
};

export const tasksApi = {
  getUsers() {
    return backendClient.get(`/getUsers/`);
  },

  retrieveTasksGroupForUser(id) {
    return backendClient.get(`/getTasksGroupForUser/` + id);
  },

  createTasksGroup(payload) {
    return backendClient.post(`/createTasksGroup`, payload);
  },

  updateTasksGroup(payload, id) {
    return backendClient.put(`/updateTasksGroup/` + id, payload);
  },

  deleteTasksGroup(id) {
    return backendClient.delete(`/deleteTasksGroup/` + id);
  },
};
