<template>
  <div :class="{ 'd-none': !active }" :id="'toolbar' + editorId">
    <div class="ql-toolbar ql-snow">
      <span class="ql-formats">
        <button type="button" class="ql-bold"></button>
        <button type="button" class="ql-italic"></button>
        <button type="button" class="ql-underline"></button>
      </span>
      <span class="ql-formats">
        <button type="button" class="ql-list" value="ordered"></button>
        <button type="button" class="ql-list" value="bullet"></button>
      </span>

      <span class="ql-formats">
        <select class="ql-color"></select>
        <select class="ql-background"></select>
      </span>
    </div>
  </div>
</template>

<script>
import EventBus from "../../eventBus";

export default {
  name: "EditorToolbar",
  data() {
    return {
      active: false,
    };
  },
  props: {
    editorId: Number,
  },

  mounted() {
    EventBus.$on("activeEditorSelected", (activeEditorId) => {
      this.active = activeEditorId == this.editorId;
    });
  },
};
</script>

<style>
.ql-toolbar {
  background-color: white;
  text-align: center;
}
</style>
