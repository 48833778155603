import axios from "axios";
import { getBackendAuthorizationHeader } from "./common";
import { saveAs } from "file-saver";
import { PRINT_PARAM } from "@/common/constants";

const pdfServiceBaseURL = process.env.VUE_APP_PDF_SERVICE_URL;

const pdfServiceClient = axios.create({
  baseURL: pdfServiceBaseURL,
});

export async function generatePdf(url, name, format, landscape) {
  const backendHost = new URL(process.env.VUE_APP_BACKEND_URL).hostname;
  let headers = {};
  headers[backendHost] = {
    Authorization: await getBackendAuthorizationHeader(),
  };
  const response = await pdfServiceClient.post(
    "/render",
    {
      url: url + "?" + PRINT_PARAM,
      format: format,
      landscape: landscape,
      headers: headers,
    },
    { responseType: "blob" }
  );
  const blob = new Blob([response.data], { type: "application/pdf" });
  saveAs(blob, name + ".pdf");
}
