import { backendClient } from "@/common/api/backend.client";
import userHelper from "@/common/userHelper";

export const docAPI = {
  getDocuments(page = 1, limit = 10, search = "") {
    return backendClient.get(
      `/getDocuments?page=${page}&limit=${limit}&search=${search}`
    );
  },

  createDocument(title, documentType, modelTemplate) {
    let user = userHelper.getUser();

    let payload = {
      title: title,
      type: documentType,
      author: user._id,
    };
    if (modelTemplate) {
      payload.modelTemplate = modelTemplate;
    }
    return backendClient.post("/createDocument", payload);
  },

  duplicateDocument(docID) {
    return backendClient.get("/duplicateDocument/" + docID);
  },

  duplicateDocumentVersions(oldDocID, newDocID) {
    return backendClient.get(
      "/duplicateDocumentVersion/" + oldDocID + "/" + newDocID
    );
  },

  duplicateCommentsThread(oldDocID, newDocID) {
    return backendClient.get(
      "/duplicateCommentsThread/" + oldDocID + "/" + newDocID
    );
  },

  deleteDocument(docID) {
    return backendClient.delete("/deleteDocument/" + docID);
  },

  editDocumentTitle(document) {
    return backendClient.put("/updateDocument/" + document._id, {
      title: document.title,
      type: document.type,
      author: document.author,
    });
  },

  getModelTemplates() {
    return backendClient.get("/getBusinessModelTemplates");
  },
};
