<template class="hide_in_print">
  <div class="hide_in_print">
    <footer id="FOOTER_1">
      <div id="DIV_109">
        <div id="DIV_110">
          <div id="DIV_111">
            <div id="DIV_112">
              <div id="DIV_113">
                <div id="DIV_114">
                  <div id="DIV_115">
                    <div id="DIV_116">
                      <div id="DIV_117">
                        <p id="P_118">
                          <span id="SPAN_119">{{ appVersion }} </span>
                          <span
                            class="text-lightAlineaColor font-light opacity-50 mx-1"
                          >
                            |
                          </span>
                          <span id="SPAN_120">© 2020 Business Roadmaps.</span>
                          <span id="SPAN_121">All rights reserved</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import version from "../../../public/status/app.json";

export default {
  data() {
    return {
      appVersion: version.version,
    };
  },
};
</script>

<style scoped>
.fa {
  vertical-align: middle;
  font-size: 7px;
  margin-right: 1em;
}

li a:hover {
  color: yellow !important;
}

#FOOTER_1 {
  box-sizing: border-box;
  display: table-row;
  text-size-adjust: 100%;
  width: 100%;
  perspective-origin: 707.692px 196.923px;
  transform-origin: 707.692px 196.923px;
  font: 16px "Open Sans", "Open Sans";
}

/*#FOOTER_1*/

#FOOTER_1:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#FOOTER_1:after*/

#FOOTER_1:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

#DIV_109 {
  bottom: 0px;
  box-sizing: border-box;
  clear: both;
  height: 59.2308px;
  left: 0px;
  position: relative;
  right: 0px;
  text-size-adjust: 100%;
  top: 0px;
  width: 100%;
  perspective-origin: 707.692px 29.6154px;
  transform-origin: 707.692px 29.6154px;
  background: rgb(255, 225, 0) none repeat scroll 0% 0% / auto padding-box
    border-box;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_109*/

#DIV_109:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_109:after*/

#DIV_109:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_109:before*/

#DIV_110 {
  bottom: 0px;
  box-sizing: border-box;
  display: table;
  height: 59.2308px;
  left: 0px;
  position: relative;
  right: 0px;
  table-layout: fixed;
  text-size-adjust: 100%;
  top: 0px;
  width: 100%;
  perspective-origin: 707.692px 29.6154px;
  transform-origin: 707.692px 29.6154px;
  font: 16px "Open Sans", "Open Sans";
  padding: 18px 36px;
}

/*#DIV_110*/

#DIV_110:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_110:after*/

#DIV_110:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_110:before*/

#DIV_111 {
  bottom: 0px;
  box-sizing: border-box;
  display: table;
  height: 23.8462px;
  left: 0px;
  min-width: 100%;
  position: relative;
  right: 0px;
  table-layout: fixed;
  text-size-adjust: 100%;
  top: 0px;
  width: 1380px;
  perspective-origin: 690px 11.9231px;
  transform-origin: 690px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
  margin: 0px 0px 0px -36px;
}

/*#DIV_111*/

#DIV_111:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_111:after*/

#DIV_111:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_111:before*/

#DIV_112 {
  bottom: 0px;
  box-sizing: border-box;
  clear: both;
  display: table-cell;
  height: 23.8462px;
  left: 0px;
  position: relative;
  right: 0px;
  text-size-adjust: 100%;
  top: 0px;
  vertical-align: top;
  width: 1380px;
  perspective-origin: 690px 11.9231px;
  transform-origin: 690px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
  padding: 0px 0px 0px 36px;
}

/*#DIV_112*/

#DIV_112:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_112:after*/

#DIV_112:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_112:before*/

#DIV_113 {
  bottom: 0px;
  box-sizing: border-box;
  height: 23.8462px;
  left: 0px;
  position: relative;
  right: 0px;
  text-size-adjust: 100%;
  top: 0px;
  width: 1344px;
  perspective-origin: 671.995px 11.9231px;
  transform-origin: 672.001px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_113*/

#DIV_113:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_113:after*/

#DIV_113:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_113:before*/

#DIV_114 {
  bottom: 0px;
  box-sizing: border-box;
  display: table;
  height: 23.8462px;
  left: 0px;
  position: relative;
  right: 0px;
  table-layout: fixed;
  text-size-adjust: 100%;
  top: 0px;
  width: 1343.85px;
  perspective-origin: 671.923px 11.9231px;
  transform-origin: 671.923px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_114*/

#DIV_114:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_114:after*/

#DIV_114:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_114:before*/

#DIV_115 {
  bottom: 0px;
  box-sizing: border-box;
  display: table-cell;
  height: 23.8462px;
  left: 0px;
  position: relative;
  right: 0px;
  text-size-adjust: 100%;
  top: 0px;
  vertical-align: top;
  width: 1343.85px;
  perspective-origin: 671.923px 11.9231px;
  transform-origin: 671.923px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
  transition: box-shadow 0.3s cubic-bezier(0.57, 0.21, 0.69, 1) 0s;
}

/*#DIV_115*/

#DIV_115:after {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_115:after*/

#DIV_115:before {
  box-sizing: border-box;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_115:before*/

#DIV_116 {
  box-sizing: border-box;
  height: 23.8462px;
  text-align: left;
  text-size-adjust: 100%;
  width: 1343.85px;
  perspective-origin: 671.923px 11.9231px;
  transform-origin: 671.923px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
  transition: box-shadow 0.3s cubic-bezier(0.57, 0.21, 0.69, 1) 0s;
}

/*#DIV_116*/

#DIV_116:after {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_116:after*/

#DIV_116:before {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_116:before*/

#DIV_117 {
  bottom: 0px;
  box-sizing: border-box;
  height: 23.8462px;
  left: 0px;
  position: relative;
  right: 0px;
  text-align: left;
  text-size-adjust: 100%;
  top: 0px;
  width: 1343.85px;
  perspective-origin: 671.923px 11.9231px;
  transform-origin: 671.923px 11.9231px;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_117*/

#DIV_117:after {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_117:after*/

#DIV_117:before {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  font: 16px "Open Sans", "Open Sans";
}

/*#DIV_117:before*/

#P_118 {
  box-sizing: border-box;
  height: 23.8462px;
  text-align: left;
  text-size-adjust: 100%;
  width: 1343.85px;
  perspective-origin: 671.923px 11.9231px;
  transform-origin: 671.923px 11.9231px;
  font: 14px / 24.5px "Open Sans", "Open Sans";
  margin: 0px;
  color: black;
}

/*#P_118*/

#P_118:after {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#P_118:after*/

#P_118:before {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#P_118:before*/

#SPAN_119 {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  perspective-origin: 0px 0px;
  transform-origin: 0px 0px;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_119*/

#SPAN_119:after {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_119:after*/

#SPAN_119:before {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_119:before*/

#SPAN_120 {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  perspective-origin: 0px 0px;
  transform-origin: 0px 0px;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_120*/

#SPAN_120:after {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_120:after*/

#SPAN_120:before {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_120:before*/

#SPAN_121 {
  box-sizing: border-box;
  text-align: left;
  margin-left: 2px;
  text-size-adjust: 100%;
  white-space: nowrap;
  perspective-origin: 0px 0px;
  transform-origin: 0px 0px;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_121*/

#SPAN_121:after {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_121:after*/

#SPAN_121:before {
  box-sizing: border-box;
  text-align: left;
  text-size-adjust: 100%;
  white-space: nowrap;
  font: 14px / 24.5px "Open Sans", "Open Sans";
}

/*#SPAN_121:before*/

h6 {
  text-transform: unset;
}
</style>
