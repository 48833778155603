import { generatePdf } from "@/common/api";
import EventBus from "../eventBus";
import { editorApi } from "../common/api";
import { externalDocApi } from "../common/api";
import { errorHandling } from "../services/ErrorService";

export default {
  data() {
    return {
      d$_commentsData: [],
      d$_docID: null,
      d$_title: "",
      d$_author: {},
      d$_documentVersions: null,
      d$_currentCommentsThread: null,
      d$_currentThreadResolved: false,
      d$_autosave: false,
      d$_modelTemplate: null,
      d$_currentVersion: {},
      d$_isSaving: false,
      d$_showTaskListSidebar: false,
      d$_sidebarActive: false,
      d$_commentSidebarActive: false,
      d$_showDefaultSpreadsheetModal: false,
      d$_spreadsheetURL: null,
      d$_externalDocumentId: null,
    };
  },
  computed: {
    $_currentVersionId: function () {
      return this.d$_currentVersion._id ? this.d$_currentVersion._id : null;
    },
    $_isNewestVersion: function () {
      if (this.$_currentVersionId) {
        return (
          this.$_currentVersionId ==
          this.d$_documentVersions[this.d$_documentVersions.length - 1]._id
        );
      } else {
        return true;
      }
    },
  },
  watch: {
    d$_currentVersion: {
      deep: true,
      handler() {
        if (this.d$_autosave) this.$_saveDocument();
      },
    },
    d$_showTaskListSidebar: function (newValue) {
      this.d$_sidebarActive = newValue;
      if (this.d$_commentSidebarActive) this.d$_commentSidebarActive = false;
    },
    d$_commentSidebarActive: function (newValue) {
      this.d$_sidebarActive = newValue;
      if (this.d$_showTaskListSidebar) this.d$_showTaskListSidebar = false;
    },
  },
  methods: {
    $_getCommentsThreadIdxById(id) {
      for (const idx in this.d$_commentsData) {
        if (this.d$_commentsData[idx]._id == id) {
          return idx;
        }
      }
    },

    $_getCommentsThreadById(id) {
      let idx = this.$_getCommentsThreadIdxById(id);
      return this.d$_commentsData[idx];
    },

    d$_saveDocumentLater() {
      setTimeout(() => {
        if (!this.d$_isSaving) {
          this.$_saveDocument();
        }
      }, 2000);
    },

    $_saveDocument: async function () {
      if (this.d$_isSaving) {
        return this.d$_saveDocumentLater();
      }
      this.d$_isSaving = true;
      await editorApi
        .saveDocument(this.d$_currentVersion.content, this.d$_docID)
        .catch((e) => {
          errorHandling(e);
        });
      this.d$_isSaving = false;
    },

    async $_createNewCommentsThread(commentInput) {
      await editorApi
        .createNewCommentsThread(this.d$_docID)
        .then(async (res) => {
          let responseComment = await editorApi
            .addComment(res.data.commentsThread._id, commentInput)
            .catch((e) => {
              errorHandling(e);
            });

          let newCommentsThread = responseComment.data.commentsThread;
          this.d$_commentsData.push(newCommentsThread);
          this.d$_currentCommentsThread = newCommentsThread;
          this.d$_currentThreadResolved = false;

          EventBus.$emit("commentsThreadCreated", res.data.commentsThread._id);

          this.$_saveDocument();
        })
        .catch((e) => {
          errorHandling(e);
        });
    },

    $_createOrAppendComment(commentInput) {
      if (this.d$_currentCommentsThread) {
        editorApi
          .addComment(this.d$_currentCommentsThread._id, commentInput)
          .then((response) => {
            let idx = this.$_getCommentsThreadIdxById(
              this.d$_currentCommentsThread._id
            );
            this.d$_commentsData[idx] = response.data.commentsThread;
            this.d$_currentCommentsThread = this.d$_commentsData[idx];
          })
          .catch((e) => {
            errorHandling(e);
          });
      } else {
        this.$_createNewCommentsThread(commentInput);
      }
    },

    $_setCommentsHistory() {
      let commentsHistoryContent = this.d$_commentsData.map((a) => ({
        date: a.comments[0].date,
        author: a.comments[0].author.name,
        firstComment: a.comments[0].text,
        numberOfComments: a.comments.length,
        threadID: a._id,
      }));

      EventBus.$emit("commentsHistoryData", commentsHistoryContent);
    },

    $_exportToPdf(format) {
      this.$toast.success(`Preparing your pdf..`, { icon: "ti-files" });

      generatePdf(
        window.location.href,
        this.d$_title,
        format.format,
        format.landscape
      ).catch((e) => {
        this.$toast.error(`${e.message + " " + "Could not export pdf"}`, {
          icon: "ti-files",
        });
      });
    },

    $_setDocumentVersions() {
      let versionsIDsandDates = this.d$_documentVersions.map((version) => ({
        vid: version._id,
        date: version.date,
        name: version.author ? version.author.name : "",
      }));
      EventBus.$emit("versionDatesInfo", versionsIDsandDates);
    },

    $_loadVersion(versionId) {
      let version = this.d$_documentVersions.find(
        (version) => version._id == versionId
      );
      this.d$_currentVersion = version;
      if (!this.$_isNewestVersion) this.d$_autosave = false;
      else this.d$_autosave = true;
      this.d$_commentSidebarActive = false;
    },

    async $_saveAndReload() {
      await this.$_saveDocument();
      this.$router.go();
    },

    async $_loadExternalDocument() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user._id == this.d$_author._id) {
        await externalDocApi
          .getExtDocuments()
          .then((res) => {
            this.d$_spreadsheetURL = res.data[0]?.publicURL;
            this.d$_externalDocumentId = res.data[0]?._id;
          })
          .catch((e) => {
            errorHandling(e);
          });
      } else {
        await externalDocApi
          .getExtDocumentsAnotherAuthor(this.d$_author._id)
          .then((res) => {
            this.d$_spreadsheetURL = res.data[0]?.publicURL;
            this.d$_externalDocumentId = res.data[0]?._id;
          })
          .catch((e) => {
            errorHandling(e);
          });
      }
    },

    $_findAndLoadVersionForCurrentCommentsThread() {
      const firstCommentDate = this.d$_currentCommentsThread.comments[0].date;
      const version = this.d$_documentVersions.find(
        (version) => version.date >= firstCommentDate
      );
      if (version) this.$_loadVersion(version._id);
      this.d$_commentSidebarActive = true;
    },

    $_showCommentsThread(commentsThreadId, threadResolved) {
      // timeout used to overwrite hiding the comment bar on editor focus
      setTimeout(() => {
        this.d$_currentThreadResolved = threadResolved;
        this.d$_currentCommentsThread =
          this.$_getCommentsThreadById(commentsThreadId);
        if (!this.d$_commentSidebarActive) this.d$_commentSidebarActive = true;
      }, 200);
    },
    $_showTaskListSidebar() {
      this.d$_showTaskListSidebar = !this.d$_showTaskListSidebar;
      EventBus.$emit("freezeindex", {});
    },
    $_toggleCommentSidebar() {
      this.d$_commentSidebarActive = !this.d$_commentSidebarActive;
      EventBus.$emit("freezeindex", {});
    },
    $_showDefaultSpreadsheet() {
      this.d$_showDefaultSpreadsheetModal = true;
    },
  },

  created() {
    this.d$_docID = this.$route.params.id.toString();
    editorApi
      .getDocumentWithCommentsAndVersion(this.d$_docID)
      .then((response) => {
        this.d$_title = response.data.document.title;
        this.d$_author = response.data.document.author;
        this.$_loadExternalDocument();
        this.d$_documentVersions = response.data.versions;
        this.d$_modelTemplate = response.data.document.modelTemplate;
        this.$_setDocumentVersions();
        if (this.d$_documentVersions.length) {
          this.d$_currentVersion =
            this.d$_documentVersions[this.d$_documentVersions.length - 1];
        }
        setTimeout(() => {
          this.d$_autosave = true;
        }, 1000);
        this.d$_commentsData = response.data.comments;
        this.$_setCommentsHistory();
      })
      .catch((e) => {
        errorHandling(e);
      });
    EventBus.$on("commentTextSubmitted", this.$_createOrAppendComment);
    EventBus.$on("exportPdf", this.$_exportToPdf);
    EventBus.$on("hideSidebar", () => {
      this.d$_commentSidebarActive = false;
    });
    EventBus.$on("loadCommentsThreadHistory", this.$_showCommentsThread);
    EventBus.$on("loadDocumentVersion", this.$_loadVersion);
    EventBus.$on("showTaskListSidebar", this.$_showTaskListSidebar);
    EventBus.$on("toggleCommentSidebar", this.$_toggleCommentSidebar);
    EventBus.$on("showDefaultSpreadsheet", this.$_showDefaultSpreadsheet);
  },

  beforeDestroy() {
    EventBus.$off("commentTextSubmitted");
    EventBus.$off("exportPdf");
    EventBus.$off("hideSidebar");
    EventBus.$off("loadCommentsThreadHistory");
  },
};
