export const colorSets = [
  [
    {
      hex: "#f44336",
      name: "Red",
    },
    {
      hex: "#1e88e5",
      name: "Blue",
    },
    {
      hex: "#388e3c",
      name: "Green",
    },
  ],
  [
    {
      hex: "#ff7043",
      name: "Orange",
    },
    {
      hex: "#ffeb3b",
      name: "Yellow",
    },
    {
      hex: "#4dd0e1",
      name: "Cyan",
    },
  ],
  [
    {
      hex: "#f06292",
      name: "Pink",
    },
    {
      hex: "#7e57c2",
      name: "Purple",
    },
    {
      hex: "#ff8a65",
      name: "LightOrange",
    },
  ],
];
