import { backendClient } from "@/common/api/backend.client";

export const externalDocApi = {
  getExtDocuments() {
    return backendClient.get("/getExtDocuments");
  },

  getExtDocumentsAnotherAuthor(author_id) {
    return backendClient.get(`/getExtDocuments/${author_id}`);
  },

  externalDocumentOpenedPing(documentId) {
    return backendClient.get(`/ExtDocumentOpened/${documentId}`);
  },
};
