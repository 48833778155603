<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container" :class="width">
        <div class="modal-header">
          <slot name="header">
            <h3>
              {{ title }}
            </h3>
          </slot>
        </div>
        <div v-if="content" class="modal-body">
          <span>{{ content }}</span>
        </div>
        <div class="modal-footer">
          <div v-if="cancelText">
            <button class="btn btn-outline-default" @click="$emit('close')">
              {{ cancelText }}
            </button>
          </div>
          <div v-if="acceptText">
            <button class="btn btn-outline-default" @click="$emit('accept')">
              {{ acceptText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    acceptText: String,
    cancelText: String,
    width: String,
  },
  data() {
    return {};
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("close");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5rem;
  justify-content: center;
  align-items: center;
}

.modal-container {
  margin: 0px auto;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
