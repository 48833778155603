<template>
  <card :title="$t('SettingsPage.card_invitation.Invite_a_new_user')">
    <form ref="form" class="row justify-content-around" @submit.prevent>
      <div class="col-md-2">
        <label class="control-label"
          >{{ $t("SettingsPage.card_invitation.Email") }}
        </label>
        <input
          class="form-control border-input pointer"
          type="email"
          placeholder="Email address"
          v-model="email"
        />
      </div>
      <div class="col-md-2">
        <label class="control-label"
          >{{ $t("SettingsPage.card_invitation.Role") }}
        </label>
        <select class="form-control border-input pointer" v-model="role">
          <option value="coach">Coach</option>
          <option value="user">User</option>
          <option value="StrategyChallenge">Strategy Challenge</option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="control-label">
          {{ $t("SettingsPage.card_invitation.Language") }}
        </label>
        <select class="form-control border-input pointer" v-model="language">
          <option value="nl">nl</option>
          <option value="en">en</option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="control-label">
          {{ $t("SettingsPage.card_invitation.Coaches") }}
        </label>
        <button
          class="form-control border-input pointer"
          @click="startCoachesModal"
        >
          {{ $t("SettingsPage.card_invitation.Adjust_now") }}
        </button>
      </div>
      <div class="col-md-3">
        <button class="btn mt-4" @click="createInvitation">
          {{ $t("commons.buttons.Submit") }}
        </button>
      </div>
    </form>
    <modal-coaches
      v-if="showCoachesModal"
      v-model="selectedCoaches"
      :availableCoaches="availableCoaches"
      @close="showCoachesModal = false"
      @select="showCoachesModal = false"
    ></modal-coaches>
  </card>
</template>

<script>
import { userApi } from "@/common/api";
import ModalCoaches from "@/components/Documents_Page/ModalCoaches";
import Vue from "vue";
import { errorHandling } from "../../services/ErrorService";

export default {
  name: "CardInvitation",
  components: {
    ModalCoaches,
  },
  data() {
    return {
      email: "",
      role: "user",
      language: "en",
      showCoachesModal: false,
      availableCoaches: [],
      selectedCoaches: [],
    };
  },
  methods: {
    createInvitation: async function () {
      if (!this.$refs.form.reportValidity()) {
        return;
      }
      await userApi
        .createInvitation({
          email: this.email,
          role: this.role,
          language: this.language,
          coaches: this.selectedCoaches,
        })
        .then((res) => {
          if (res.status === 200) {
            this.$toast.success(`${this.$t("notifications.Saved")}`, {
              icon: "ti-files",
            });
          }
        })
        .catch((e) => {
          if (e.response) {
            Vue.$toast.error(e.response.data);
          } else {
            Vue.$toast.error(e.message);
          }
          errorHandling(e);
        });

      (this.language = "en"), (this.email = ""), (this.role = "user");
    },
    startCoachesModal() {
      this.selectedCoaches = [];
      this.userForUpdatingCoaches = null;
      this.showCoachesModal = true;
    },
    getAvailableCoaches() {
      userApi
        .getAvailableCoachesList()
        .then((response) => {
          this.availableCoaches = response.data.coaches;
        })
        .catch((e) => {
          Vue.$toast.error(e.message);
          errorHandling(e);
        });
    },
  },
  created() {
    this.getAvailableCoaches();
  },
};
</script>

<style scoped></style>
