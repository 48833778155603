<template>
  <div>
    <modal classes="task-modal w-75" @close="close()">
      <template slot="header">
        <inline-edit
          :startWithEdit="!taskTitle"
          :value="taskTitle"
          @input="$emit('update-task-title', $event)"
          class="d-flex"
        >
          <h3 class="m-0">{{ taskTitle }}</h3>
          <span
            class="ti-trash icon-button text-gray mx-2 my-auto"
            @click="$emit('delete-task')"
          />
        </inline-edit>

        <a
          @click="$emit('mark-task-done')"
          type="button"
          class="btn btn-link text-success"
        >
          {{ $t("documents_page.tasks_list.Mark_as_done") }}
        </a>
      </template>
      <template slot="body">
        <div class="editorplaceholder">
          <quill-editor
            :value="taskContent"
            @input="
              $emit('update-task-content', $event);
              edited = true;
            "
            @ready="callRegisterCustomColor()"
            class="editor"
          />
        </div>
      </template>
      <template slot="footer">
        <span class="my-auto mr-auto">
          {{ $t("documents_page.tasks_list.task_for") }} {{ assigneeName }},
          {{ $t("documents_page.tasks_list.created_by") }} {{ author }},
          {{ date | formatDate }}
        </span>
        <button class="btn btn-outline-default" @click="close()">
          {{ $t("commons.buttons.Close") }}
        </button>
      </template>
    </modal>
    <GeneralModal
      v-if="showDeleteTaskModal"
      :title="deleteTaskModalTitle"
      :acceptText="$t('commons.buttons.Yes')"
      :cancelText="$t('commons.buttons.No')"
      width="w-25"
      @close="toggleDeleteTaskModal"
      @accept="$emit('delete-task')"
    />
  </div>
</template>

<script>
import Modal from "../Modal";
import { quillEditor } from "vue-quill-editor";
import { InlineEdit } from "@/components/index";
import registerCustomColor from "../../common/quillBlots";
import GeneralModal from "../Modals/GeneralModal";

export default {
  data() {
    return {
      edited: false,
      showDeleteTaskModal: false,
      deleteTaskModalTitle: "",
    };
  },
  components: {
    Modal,
    InlineEdit,
    quillEditor,
    GeneralModal,
  },
  props: {
    taskTitle: String,
    taskContent: String,
    assigneeName: String,
    date: Date,
    author: String,
  },
  methods: {
    close() {
      if (!this.taskTitle) {
        return this.toggleDeleteTaskModal(
          this.$t(
            "documents_page.tasks_list.task_title_is_empty_would_you_like_to_delete"
          )
        );
      }
      if (this.edited) {
        this.$emit("save-and-close");
      } else {
        this.$emit("close");
      }
    },
    toggleDeleteTaskModal(title) {
      this.deleteTaskModalTitle = title;
      this.showDeleteTaskModal = !this.showDeleteTaskModal;
    },
    callRegisterCustomColor() {
      registerCustomColor();
    },
  },
};
</script>
<style lang="scss">
.task-modal .ql-editor {
  min-height: 10rem;
}
</style>
