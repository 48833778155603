<template>
  <modal
    :title="$t('documents_page.coaches_modal.Select_your_coaches')"
    @close="$emit('close')"
  >
    <template slot="body">
      <multiselect
        v-model="selectedCoachesInternal"
        :options="availableCoaches"
        label="name"
        track-by="_id"
        :multiple="true"
      ></multiselect>
    </template>
    <template slot="footer">
      <button
        class="btn btn-default"
        @click="$emit('select', selectedCoachesInternal)"
      >
        {{ $t("commons.buttons.Save") }}
      </button>
      <button class="btn btn-outline-default" @click="$emit('close')">
        {{ $t("commons.buttons.Close") }}
      </button>
    </template>
  </modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Modal from "../Modal";

export default {
  components: {
    Multiselect,
    Modal,
  },

  model: {
    prop: "selectedCoaches",
    event: "select",
  },

  props: {
    selectedCoaches: Array,
    availableCoaches: Array,
  },

  data() {
    return {
      selectedCoachesInternal: this.selectedCoaches,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
