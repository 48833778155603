import { errorHandling } from "@/services/ErrorService";
import axios from "axios";
import { getBackendAuthorizationHeader } from "./common";

const backendBaseURL = process.env.VUE_APP_BACKEND_URL;

const backendClient = axios.create({
  baseURL: backendBaseURL,
});

async function addAuthorizationHeader(config) {
  config.headers.Authorization = await getBackendAuthorizationHeader();
  return config;
}

backendClient.interceptors.request.use(
  (config) => {
    return addAuthorizationHeader(config);
  },
  (error) => {
    if (error.response && error.response.status >= 500) {
      errorHandling(new Error(error.response.data.message));
    }
    return Promise.reject(error);
  }
);

backendClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status >= 500) {
      errorHandling(new Error(error.response.data.message));
    }

    return Promise.reject(error);
  }
);

export { backendClient };
